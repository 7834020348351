import { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'

import { handleError } from '../utils/utils.ts'

import { ProductUpdateStatus } from '../types/enums/priceUpdateStatus.enum.ts'

import {
    Price,
    ProductPrices,
    ProductWithStatus,
} from '../types/priceAutomation'

const isPricesValid = (prices: Price[]) => {
    return prices.every((p1) => p1.price !== null)
}

export function useSkuPricesFromGoogleSheet() {
    const [products, setProducts] = useState<ProductWithStatus[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    const loadGoogleSheetData = async () => {
        setError(undefined)
        setIsLoading(true)

        try {
            const { data } = await axios.get('/api/v2/inapppurchases/products')

            const newProducts = data.map((product: ProductPrices) => ({
                ...product,
                status: isPricesValid(product.prices)
                    ? ProductUpdateStatus.NotStarted
                    : ProductUpdateStatus.InvalidPrice,
            }))

            setProducts(newProducts)
        } catch (error) {
            const errorMessage = handleError(
                'Could not load sku prices from the spreadsheet',
                error,
                false
            )

            setProducts([])
            setError(errorMessage)
        }

        setIsLoading(false)
    }

    return {
        productsToUpdate: products,
        fetchProducts: loadGoogleSheetData,
        isLoading: isLoading,
        error,
    }
}

export function usePriceUpdater() {
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [productsUpdateState, setProductsUpdateState] = useState<
        ProductWithStatus[]
    >([])

    // todo Delete me! >
    function pickRandomNAndUs(prices: Price[], n: number): Price[] {
        const usPrice = prices.find((p) => p.territoryId === 'USA')

        if (!usPrice) {
            throw new Error('US territory price not found')
        }

        return _.shuffle(
            prices.filter(
                (p) => p.territoryId !== 'USA' && p.territoryId !== 'XKX'
            )
        )
            .slice(0, n)
            .concat([usPrice])
    }

    const AllowedProductIds = [
        'com.tocaboca.tocalifeworld.pricetest1',
        'com.tocaboca.tocalifeworld.pricetest2',
        'com.tocaboca.tocalifeworld.pricetest3',
    ]
    let lastProductIdIndex = 0
    // <

    const updateProduct = async ({
        productId,
        prices,
    }: ProductWithStatus): Promise<ProductWithStatus> => {
        // todo Delete me! >
        if (lastProductIdIndex >= AllowedProductIds.length) {
            lastProductIdIndex = 0
        }

        const fakeProductId = AllowedProductIds[lastProductIdIndex++]
        // <

        try {
            await axios.post(`/api/v2/inapppurchases/${fakeProductId}/prices`, {
                prices: pickRandomNAndUs(prices, 5),
            })

            return {
                prices,
                productId,
                status: ProductUpdateStatus.Updated,
                error: '',
            }
        } catch (error) {
            const errorMessage = handleError(
                'Could not update the prices for this SKU',
                error,
                false
            )

            return {
                prices,
                productId,
                status: ProductUpdateStatus.Error,
                error: errorMessage,
            }
        }
    }

    const AllowedStatuses = [
        ProductUpdateStatus.Error,
        ProductUpdateStatus.NotStarted,
    ]

    const updateProducts = async (products: ProductWithStatus[]) => {
        setIsUpdating(true)

        const productsToUpdate = products
            ?.filter((product) => AllowedStatuses.includes(product.status))
            .map((product) => {
                return {
                    ...product,
                    status: ProductUpdateStatus.Scheduled,
                }
            })

        setProductsUpdateState(Array.from(productsToUpdate))

        for (const [index, product] of productsToUpdate.entries()) {
            productsToUpdate.splice(index, 1, {
                ...product,
                status: ProductUpdateStatus.Updating,
            })

            setProductsUpdateState(Array.from(productsToUpdate))

            const productUpdateResult = await updateProduct(product)

            productsToUpdate.splice(index, 1, productUpdateResult)

            setProductsUpdateState(Array.from(productsToUpdate))
        }

        setIsUpdating(false)
    }

    return {
        updateProductsPrices: updateProducts,
        productsUpdateState: productsUpdateState,
        isUpdatingPrices: isUpdating,
    }
}

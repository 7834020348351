import React from 'react'
import classNames from 'classnames'

import styles from './PreviewTable.module.scss'

export enum ChangeStatuses {
    NEW = 'NEW',
    UPDATED = 'UPDATED',
    INVALID = 'INVALID',
    NO_CHANGES = 'NO_CHANGES',
}
export const statusToDisplayText = {
    [ChangeStatuses.NEW]: 'New',
    [ChangeStatuses.UPDATED]: 'Updated',
    [ChangeStatuses.INVALID]: 'Invalid',
    [ChangeStatuses.NO_CHANGES]: 'No changes',
}

export type ChangeStatusesUnion = `${ChangeStatuses}`

export const statusToCSSClass = {
    [ChangeStatuses.NEW]: 'new',
    [ChangeStatuses.UPDATED]: 'updated',
    [ChangeStatuses.INVALID]: 'invalid',
    [ChangeStatuses.NO_CHANGES]: 'noChanges',
}

type StatusLabelProps = {
    status: ChangeStatusesUnion
}

function StatusLabel(props: StatusLabelProps) {
    const { status } = props
    return (
        <div
            className={classNames(
                styles.changeStatus,
                styles[statusToCSSClass[status]]
            )}
        >
            {statusToDisplayText[status]}
        </div>
    )
}

export default StatusLabel

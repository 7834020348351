import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AxiosResponse } from 'axios'
import _ from 'lodash'

import { DraggableItemType } from '../../shared/Draggable.tsx'
import { DragNDropSpeechBubble } from './DragNDropSpeechBubble.tsx'
import {
    DragDropContainer,
    Draggable,
    DraggablePhotos,
    MediaImage,
    SpeechBubble,
} from '../../shared'

import {
    ImageFileUploading,
    onSelectPhoto,
    OptionalData,
} from '../../utils/upload'
import { getPackDisplayName } from '../../utils/utils.ts'

import { packScreenshotDimensions } from '../../settings'

import { ImageIconType } from '../../types/enums/imageicontype.enum.ts'
import { MediaType } from '../../types/enums/mediatype.enum.ts'
import { PackType } from '../../types/enums/packtype.enum.ts'

import { Media } from '../../types/mediaTypes'
import { Pack } from '../../types/packTypes'

import wormImg from '../../assets/worm.png'

type PackScreenshotsSectionProps = {
    pack: Pack
    childPacksData: Pack[]
    items: (Media | (ImageFileUploading & { id?: number }))[]
    onMediasSelected: (
        type: MediaType,
        items: ImageFileUploading[],
        optionalData: OptionalData
    ) => void
    onMediasWasUploaded: (
        type: MediaType,
        file: File,
        res: AxiosResponse<{ uploaded: { id: number } }>,
        optionalData: OptionalData
    ) => void
    onMediaDelete: (index: number, type: MediaType) => void
    onMediaDragEnd: (result: {
        source: { index: number; droppableId: MediaType }
        destination: { index: number }
    }) => void
    toggleScreenshot: (mediaId: number) => void
}

export const PackScreenshotsSection = (props: PackScreenshotsSectionProps) => {
    const {
        pack,
        childPacksData,
        items,
        onMediasSelected,
        onMediasWasUploaded,
        onMediaDragEnd,
        onMediaDelete,
        toggleScreenshot,
    } = props

    const isBundle = pack.type === PackType.MULTIPACK

    // Map screenshots with info from child packs media
    const draggableItems: DraggableItemType[] = items.map(
        (parentScreenshot) => {
            let overlayText = pack.displayName.en ?? pack.name
            let buttonType = ImageIconType.DELETE

            _.forEach(childPacksData, (pack) => {
                const mediaExistsInTheChild = pack.medias.find(
                    (childMedia) => childMedia.id === parentScreenshot.id
                )
                if (mediaExistsInTheChild) {
                    overlayText = getPackDisplayName(pack)
                    buttonType = ImageIconType.CHECK
                    return
                }
            })

            return {
                // As we put uploading files into screenshots, we need to map media object for render component when it has a file
                media:
                    'file' in parentScreenshot
                        ? {
                              id: parentScreenshot.id,
                              url: parentScreenshot.url,
                          }
                        : parentScreenshot,
                overlayText: overlayText,
                buttonType: buttonType,
                onClick: toggleScreenshot,
            }
        }
    )

    return (
        <>
            <h3>Screenshots (2208×1242)</h3>
            {items.length > 0 ? (
                isBundle ? (
                    <SpeechBubble
                        style={{
                            justifyContent: 'left',
                            marginTop: 10,
                            marginBottom: 20,
                        }}
                    >
                        Rearrange the gallery with drag and drop. You can
                        de-select child pack related media. To add child pack
                        media go to the section above.
                        <br />
                        To upload bundle specific media, simply drag and drop
                        them below.
                    </SpeechBubble>
                ) : (
                    <DragNDropSpeechBubble />
                )
            ) : null}
            <DndProvider backend={HTML5Backend}>
                <DragDropContainer
                    data={pack}
                    type={MediaType.SCREENSHOT}
                    onMediasSelected={onMediasSelected}
                    onMediasWasUploaded={onMediasWasUploaded}
                >
                    {items.length === 0 && (
                        <div className="noMediaWrapper">
                            <img
                                alt="placeholder no screenshots uploaded"
                                src={wormImg}
                            ></img>
                            <label className="placeholder">
                                No screenshots, just yet. Drag and drop here to
                                upload :)
                            </label>
                        </div>
                    )}
                    {isBundle && (
                        <Draggable
                            items={draggableItems}
                            onDragEnd={onMediaDragEnd}
                            renderer={(item) => <MediaImage {...item} />}
                        />
                    )}
                    {!isBundle && (
                        <DraggablePhotos
                            onDelete={onMediaDelete}
                            type={MediaType.SCREENSHOT}
                            items={items}
                            onDragEnd={onMediaDragEnd}
                        />
                    )}
                </DragDropContainer>
            </DndProvider>
            <div style={{ marginTop: 20 }}>
                <input
                    type="file"
                    multiple={true}
                    name=""
                    id="screenshots"
                    accept="Image/png"
                    onChange={(e) =>
                        onSelectPhoto(
                            e,
                            MediaType.SCREENSHOT,
                            onMediasSelected,
                            onMediasWasUploaded,
                            {
                                fileName: pack.packId + '_sc',
                                expectedSize: packScreenshotDimensions,
                            }
                        )
                    }
                />
                <label className="upload" htmlFor="screenshots">
                    Add new
                </label>
            </div>
        </>
    )
}

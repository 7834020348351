import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import logoImg from './assets/logo.svg'

const Nav = ({
    isAuthenticated,
    navigate,
    tab,
    isInAppEventsEnabled,
    children,
}) => {
    const [isSubMenuShown, setIsSubMenuShown] = useState(false)
    return (
        <nav
            onClick={() => setIsSubMenuShown(false)}
            className="blurrable navigation"
            style={{
                display: isAuthenticated ? 'flex' : 'none',
            }}
        >
            <div className="linksWrapper">
                <Link
                    onClick={(e) => navigate(e, 'packs')}
                    to="/packs/"
                    style={{ margin: 0, display: 'block' }}
                >
                    <img alt="tocaboca_logo" className="logo" src={logoImg} />
                </Link>
                <Link
                    onClick={(e) => navigate(e, 'packs')}
                    style={tab === 'packs' ? { color: '#759EAA' } : {}}
                    to="/packs/"
                >
                    Packs
                </Link>
                <Link
                    onClick={(e) => navigate(e, 'carousels')}
                    style={tab === 'carousels' ? { color: '#759EAA' } : {}}
                    to="/carousels/"
                >
                    Carousels
                </Link>
                <Link
                    onClick={(e) => navigate(e, 'shoptabs')}
                    style={tab === 'shoptabs' ? { color: '#759EAA' } : {}}
                    to="/shoptabs/"
                >
                    Shop Tabs
                </Link>
                <Link
                    onClick={(e) => navigate(e, 'maps')}
                    style={tab === 'maps' ? { color: '#759EAA' } : {}}
                    to="/maps/"
                >
                    Maps
                </Link>
                <Link
                    onClick={(e) => navigate(e, 'gifts')}
                    style={tab === 'gifts' ? { color: '#759EAA' } : {}}
                    to="/gifts/"
                >
                    Gifts
                </Link>
                <Link
                    onClick={(e) => navigate(e, 'medias')}
                    style={tab === 'medias' ? { color: '#759EAA' } : {}}
                    to="/medias/"
                >
                    Media Library
                </Link>
                <Link
                    onClick={(e) => navigate(e, 'accounts')}
                    style={tab === 'accounts' ? { color: '#759EAA' } : {}}
                    to="/accounts/"
                >
                    Account management
                </Link>
                <div
                    style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        margin: '28px 20px',
                    }}
                    onClick={(e) => {
                        setIsSubMenuShown(!isSubMenuShown)
                        e.stopPropagation()
                    }}
                >
                    More <span style={{ fontSize: 11 }}>▼</span>
                    {isSubMenuShown && (
                        <div id="submenu">
                            <Link
                                onClick={(e) => navigate(e, 'versions')}
                                style={
                                    tab === 'versions'
                                        ? { color: '#759EAA' }
                                        : {}
                                }
                                to="/versions/"
                            >
                                Release management
                            </Link>
                            {isInAppEventsEnabled && (
                                <Link
                                    onClick={(e) => navigate(e, 'iaes')}
                                    style={
                                        tab === 'iaes'
                                            ? { color: '#759EAA' }
                                            : {}
                                    }
                                    to="/iaes/"
                                >
                                    In-App Events
                                </Link>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="childrenWrapper">{children}</div>
        </nav>
    )
}

export default Nav

import React, { useState } from 'react'

import { mapSignPosition } from '../settings'

function Sign(props) {
    const [c, nextColor] = useState(0)
    const { name, height } = props

    let nc = c + 1
    if (c > 2) nc = 0
    return (
        <div
            onClick={() => nextColor(nc)}
            className={'sign style' + c}
            style={{ marginTop: mapSignPosition * height, zIndex: 100000 }}
        >
            {name}
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}

export default Sign

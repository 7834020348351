import React from 'react'

import styles from './PriceIdDeleteButton.module.scss'

import deleteImg from '../../assets/delete.png'

type DeleteButtonProps = {
    onClick: () => void
}

export const PriceIdDeleteButton = (props: DeleteButtonProps) => {
    const { onClick } = props

    return (
        <button
            className={styles.deleteButton}
            type="button"
            onClick={onClick}
            data-testid="delete-button"
        >
            <img
                alt="Remove product ID and SKUs"
                className={styles.img}
                src={deleteImg}
            />
        </button>
    )
}

import React, { useState } from 'react'
import classNames from 'classnames'

import { ImageIconTypeUnion } from '../types/enums/imageicontype.enum'
import { MediaType } from '../types/enums/mediatype.enum'

import { Media } from '../types/mediaTypes'

import styles from './MediaImage.module.scss'

type MediaImageProps<T, K> = {
    media: T
    buttonType?: ImageIconTypeUnion
    overlayText?: string
    onClick?: (mediaId: K) => void
}

function MediaImage<T extends Omit<Media, 'id' | 'created'> & { id: K }, K>(
    props: MediaImageProps<T, K>
) {
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const { media, buttonType, onClick, overlayText } = props
    return (
        <>
            <div className={styles.mediaContainer}>
                {media.type === MediaType.VIDEO && (
                    <video preload="metadata">
                        <source src={`${media.url}#t=1`} type="video/mp4" />
                    </video>
                )}
                {media.type !== MediaType.VIDEO && (
                    <img
                        data-testid={'media-id-' + media.id}
                        // TODO have dynamic image size, @1x, @2x, @3x
                        src={media.url.replace('{0}', '@1x')}
                        alt={'id:' + media.id}
                        onLoad={() => setIsLoaded(true)}
                    />
                )}
                {overlayText && (
                    <div className={styles.overlay}>{overlayText}</div>
                )}
                {isLoaded && buttonType && (
                    <button
                        className={classNames(
                            styles.buttonIcon,
                            styles[buttonType]
                        )}
                        onClick={() => onClick && onClick(media.id)}
                    />
                )}
            </div>
        </>
    )
}

export default MediaImage

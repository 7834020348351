import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AxiosResponse } from 'axios'

import Thumbnail from '../Thumbnail'
import { DragNDropSpeechBubble } from './DragNDropSpeechBubble.tsx'
import { DragDropContainer } from '../../shared'

import { ImageFileUploading, OptionalData } from '../../utils/upload'

import { MediaType } from '../../types/enums/mediatype.enum.ts'

import { Media } from '../../types/mediaTypes'
import { Pack } from '../../types/packTypes'

export type AllowedMediaTypes =
    | MediaType.IAP_IMAGE
    | MediaType.TAB_THUMBNAIL
    | MediaType.WIDE_THUMBNAIL
    | MediaType.THUMBNAIL

type PackMediaSectionProps = {
    type: AllowedMediaTypes
    pack: Pack
    items: (Media | (ImageFileUploading & { id?: number }))[]
    onMediasSelected: (
        type: MediaType,
        items: ImageFileUploading[],
        optionalData: OptionalData
    ) => void
    onMediasWasUploaded: (
        type: MediaType,
        file: File,
        res: AxiosResponse<{ uploaded: { id: number } }>
    ) => void
    onMediaDelete: (index: number, type: MediaType) => void
    onMediaDragEnd: (result: {
        source: { index: number; droppableId: MediaType }
        destination: { index: number }
    }) => void
    header: string
}

export const PackMediaSection = (props: PackMediaSectionProps) => {
    const {
        type,
        pack,
        items,
        onMediasSelected,
        onMediasWasUploaded,
        onMediaDelete,
        onMediaDragEnd,
        header,
    } = props

    const getFileSuffix = () => {
        const suffixes = {
            [MediaType.IAP_IMAGE]: '_iap',
            [MediaType.TAB_THUMBNAIL]: '_tab_thumb',
            [MediaType.WIDE_THUMBNAIL]: '_wthumb',
            [MediaType.THUMBNAIL]: '_thumb',
        }

        return suffixes[type]
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <DragDropContainer
                data={pack}
                type={type}
                onMediasSelected={onMediasSelected}
                onMediasWasUploaded={onMediasWasUploaded}
            >
                <h3>{header}</h3>
                {items.length ? <DragNDropSpeechBubble /> : null}
                <Thumbnail
                    id={type}
                    fileName={pack.packId + getFileSuffix()}
                    isMultiple={false}
                    type={type}
                    items={items}
                    onDelete={onMediaDelete}
                    onSelect={onMediasSelected}
                    onUpload={onMediasWasUploaded}
                    onDragPhotoEnd={onMediaDragEnd}
                />
            </DragDropContainer>
        </DndProvider>
    )
}

import React from 'react'
import classNames from 'classnames'

import styles from './PriceIdExpandButton.module.scss'

import openCloseImg from '../../assets/openclose.png'

type OpenCloseButtonProps = {
    isOpen: boolean
    onClick: () => void
}

export const PriceIdExpandButton = (props: OpenCloseButtonProps) => {
    const { isOpen, onClick } = props

    return (
        <button
            className={styles.openCloseButton}
            type="button"
            onClick={onClick}
        >
            <img
                alt="open/close"
                className={classNames(
                    styles.img,
                    isOpen ? 'arrow down' : 'arrow right'
                )}
                src={openCloseImg}
            />
        </button>
    )
}

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import axios from 'axios'

import Accounts from './accounts/Accounts'
import Carousel from './carousels/Carousel'
import Carousels from './carousels/Carousels'
import Gift from './gifts/Gift'
import Gifts from './gifts/Gifts'
import InAppEvent from './inappevents/InAppEvent'
import InAppEvents from './inappevents/InAppEvents'
import Login from './Login'
import Map from './maps/Map'
import Maps from './maps/Maps'
import Medias from './media/Medias.tsx'
import Nav from './Nav'
import Pack from './packs/Pack'
import Packs from './packs/Packs'
import LanguageSelector from './shared/LanguageSelector'
import Loading from './shared/Loading'
import ShopTab from './shopTabs/ShopTab/ShopTab'
import ShopTabs from './shopTabs/ShopTabs'
import SkuUpdate from './sku-update/SkuUpdate'
import Tools from './tools/Tools'
import { User } from './user/User'
import Versions from './versions/Versions'

import { Features, isFeatureEnabled } from './utils/features.ts'

class App extends Component {
    state = {
        isLoading: true,
        isAuthenticated: false,
        openSections: ['packOverview'], // Contains list of Section.key to control open sections
        language: localStorage.getItem('language') || 'en',
        isInAppEventsEnabled: false,
        user: null,
    }

    componentDidMount = async () => {
        try {
            const isInAppEventsEnabled = await isFeatureEnabled(
                Features.in_app_events
            )
            const {
                data: { user },
            } = await axios.get('/api/helloAuthenticated')
            this.setState({
                isLoading: false,
                isAuthenticated: true,
                user,
                isInAppEventsEnabled,
            })
            localStorage.setItem('hasUnsavedChanges', false)
        } catch (e) {
            this.setState({
                isLoading: false,
                isAuthenticated: false,
                user: null,
            })
        }
    }

    signout = async (e) => {
        if (!this.onCheckUnsaved(e)) return

        await axios.get('/auth/logout')

        window.location = '/login'
    }

    toggleSection = (name) => {
        console.log('Toggle section', name)
        let openSections = this.state.openSections
        let i = openSections.indexOf(name)
        if (i > -1) openSections.splice(i, 1)
        else openSections.push(name)
        this.setState({ openSections })
    }

    onCheckUnsaved = (e) => {
        let hasUnsaved = localStorage.getItem('hasUnsavedChanges') === 'true'
        if (!hasUnsaved) return true
        if (!window.confirm('Really leave? You have unsaved changes.')) {
            e.preventDefault()
            return false
        }
        return true
    }

    navigate = (e, tab) => {
        if (this.onCheckUnsaved(e)) {
            this.setState({ tab, hasUnsavedChanges: false })
            localStorage.setItem('hasUnsavedChanges', false)
        }
    }

    render() {
        const { isLoading, isAuthenticated, language, isInAppEventsEnabled } =
            this.state
        const navigate = this.navigate
        if (isLoading) return <Loading />

        if (!isAuthenticated) return <Login />

        let tab = this.state.tab
        if (!tab) {
            let url = window.location.href
            if (url.indexOf('pack') > 1) tab = 'packs'
            if (url.indexOf('gift') > 1) tab = 'gifts'
            if (url.indexOf('map') > 1) tab = 'maps'
            if (url.indexOf('carousel') > 1) tab = 'carousels'
            if (url.indexOf('shoptab') > 1) tab = 'shoptabs'
            if (url.indexOf('tools') > 1) tab = 'tools'
            if (url.indexOf('versions') > 1) tab = 'versions'
            if (url.indexOf('iae') > 1) tab = 'iaes'
        }

        const allowedOrigins = [
            'http://localhost:*',
            'https://lifeserver-admin.tocaboca.com',
            'https://lifeserver-admin-staging.tocaboca.com',
            'https://lifeserver-admin-dev-growth.tocaboca.com',
            'https://lifeserver-admin-dev-id.tocaboca.com',
            'https://lifeserver-admin-dev-os.tocaboca.com',
            'https://s3.amazonaws.com',
            'https://s3-eu-west-1.amazonaws.com',
        ]

        const contentSecurityPolicy = `connect-src ${allowedOrigins.join(' ')}`
        return (
            <>
                <Helmet>
                    <meta
                        httpEquiv="Content-Security-Policy"
                        content={contentSecurityPolicy}
                    />
                </Helmet>
                <Router>
                    <div>
                        <Nav
                            tab={tab}
                            isAuthenticated={isAuthenticated}
                            isInAppEventsEnabled={isInAppEventsEnabled}
                            navigate={navigate}
                        >
                            <LanguageSelector
                                onChange={(e) => {
                                    this.setState({ language: e.target.value })
                                }}
                                language={language}
                                value={language}
                            />

                            {this.state.user && (
                                <User
                                    user={this.state.user}
                                    signout={this.signout}
                                />
                            )}
                        </Nav>
                        <div className="blurrable routesWrapper">
                            <Routes>
                                <Route path="/login/" element={<Login />} />
                                <Route
                                    path="/sku-update/"
                                    element={<SkuUpdate />}
                                />
                                <Route
                                    path="/packs/"
                                    element={<Packs language={language} />}
                                />
                                <Route
                                    path="/pack/:id"
                                    element={
                                        <Pack
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route path="/gifts/" element={<Gifts />} />
                                <Route path="/gift/:id" element={<Gift />} />
                                <Route path="/maps/" element={<Maps />} />
                                <Route
                                    path="/map/:id"
                                    element={<Map language={language} />}
                                />
                                <Route path="/newgift" element={<Gift />} />
                                <Route
                                    path="/newpack"
                                    element={
                                        <Pack
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path="/carousels/"
                                    element={<Carousels />}
                                />
                                <Route
                                    path="/shoptabs/"
                                    element={<ShopTabs />}
                                />
                                <Route
                                    path="/newshoptab"
                                    element={
                                        <ShopTab
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path="/shoptab/:id"
                                    element={
                                        <ShopTab
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path="/accounts/"
                                    element={<Accounts />}
                                />
                                <Route
                                    path="/versions/"
                                    element={<Versions />}
                                />
                                <Route
                                    path="/carousel/:id"
                                    element={
                                        <Carousel
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route
                                    path="/newcarousel"
                                    element={
                                        <Carousel
                                            language={language}
                                            openSections={
                                                this.state.openSections
                                            }
                                            toggleSection={this.toggleSection}
                                        />
                                    }
                                />
                                <Route path="/medias" element={<Medias />} />
                                <Route path="/tools" element={<Tools />} />
                                {isInAppEventsEnabled && (
                                    <>
                                        <Route
                                            path="/iaes/"
                                            element={
                                                <InAppEvents
                                                    language={
                                                        this.state.language
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path="/iae/:id"
                                            element={
                                                <InAppEvent
                                                    language={
                                                        this.state.language
                                                    }
                                                />
                                            }
                                        />
                                        <Route
                                            path="/newiae"
                                            element={
                                                <InAppEvent
                                                    language={
                                                        this.state.language
                                                    }
                                                />
                                            }
                                        />
                                    </>
                                )}
                            </Routes>
                        </div>
                    </div>
                </Router>
            </>
        )
    }
}

export default App

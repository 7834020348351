import React, {
    ChangeEvent,
    MouseEvent,
    useEffect,
    useRef,
    useState,
} from 'react'
import _ from 'lodash'

import { PriceIdDeleteButton } from './PriceIdDeleteButton.tsx'
import { PriceIdExpandButton } from './PriceIdExpandButton.tsx'
import { LabelInput, Modal, SpeechBubble } from '../../shared'

import { Pack, PriceLevel } from '../../types/packTypes'

import { emptyPriceLevel } from '../emptyPack.ts'

enum PossibleInputFieldsNames {
    Min = 'min',
    Max = 'max',
    Price = 'price',
}

type PackPriceIdProps = {
    pack: Pack
    onPriceLevelsChange: (pls: PriceLevel[]) => void
}

export function PackPriceId(props: PackPriceIdProps) {
    const { pack, onPriceLevelsChange } = props
    const [expandedPrice, setExpandedPrice] = useState<number | null>(null)
    const priceInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const lastIndex = pack.priceLevels.length - 1

        if (lastIndex < 0) {
            return
        }

        // If new price level is added
        if (!pack.priceLevels[lastIndex].productId) {
            setExpandedPrice(lastIndex)
        }

        priceInput.current?.focus()
    }, [pack.priceLevels.length])

    const onPriceLevelSkuChange = (
        e: ChangeEvent<HTMLInputElement>,
        priceLevelIndex: number
    ) => {
        const newValue = e.target.value
        const product = pack.priceLevels[priceLevelIndex]

        const platformData = product.platformData.map((pd) => ({
            ...pd,
            sku: product.productId === pd.sku ? newValue : pd.sku,
        }))

        onPriceLevelsChange(
            _.set([...pack.priceLevels], priceLevelIndex, {
                ...product,
                productId: newValue,
                platformData,
            })
        )
    }

    const onInputFieldChange = (
        e: ChangeEvent<HTMLInputElement>,
        priceLevelIndex: number
    ) => {
        const newValue = e.target.value
        const name = e.target.name as PossibleInputFieldsNames
        const product = pack.priceLevels[priceLevelIndex]

        onPriceLevelsChange(
            _.set([...pack.priceLevels], priceLevelIndex, {
                ...product,
                [name]:
                    // Set empty price to null so that js doesn't turn '' into 0
                    name === PossibleInputFieldsNames.Price && newValue === ''
                        ? null
                        : Number(newValue),
            })
        )
    }

    const onPlatformDataChange = (
        e: ChangeEvent<HTMLInputElement>,
        priceLevelIndex: number,
        platformDataIndex: number
    ) => {
        const name = e.target.name
        const value =
            e.target.type === 'checkbox' ? e.target.checked : e.target.value

        onPriceLevelsChange(
            _.set(
                _.cloneDeep(pack.priceLevels),
                [priceLevelIndex, 'platformData', platformDataIndex, name],
                value
            )
        )
    }

    const deletePriceId = async (index: number) => {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete product',
            text: 'Really delete this product?',
            okLabel: 'Yes do it!',
        })

        if (!isConfirmed) return

        onPriceLevelsChange(
            _.without(pack.priceLevels, pack.priceLevels[index])
        )
    }

    const onNewPriceIdAdded = () => {
        const priceLevel = _.cloneDeep(emptyPriceLevel) as unknown as PriceLevel

        onPriceLevelsChange(_.concat(pack.priceLevels, [priceLevel]))
    }

    const archiveSKU = async (
        _e: MouseEvent<HTMLButtonElement>,
        priceLevelIndex: number,
        platformDataIndex: number
    ) => {
        const isConfirmed = await Modal.confirm({
            text: 'This will clear the SKU and store it in history log.',
            okLabel: 'Sounds good, make it happen!',
        })

        if (!isConfirmed) return

        const priceLevels = _.cloneDeep(pack.priceLevels)
        const modifiedPlatformData =
            priceLevels[priceLevelIndex].platformData[platformDataIndex]

        if (!modifiedPlatformData.deprecatedSkus) {
            modifiedPlatformData.deprecatedSkus = []
        }

        modifiedPlatformData.deprecatedSkus.push(modifiedPlatformData.sku)
        modifiedPlatformData.sku = ''

        onPriceLevelsChange(priceLevels)
    }

    const showSKUHistory = (
        e: MouseEvent<HTMLButtonElement>,
        priceLevelIndex: number,
        platformDataIndex: number
    ) => {
        e.preventDefault()

        const history =
            pack.priceLevels[priceLevelIndex].platformData[platformDataIndex]
                .deprecatedSkus
        const output =
            history.reduce((acc, record) => `${acc}- ${record}\n`, '') ||
            'Nothing to see here :)'

        alert(`Archived SKUS:\n\n${output}`)
    }

    return (
        <>
            <h4>Enter SKU names below.</h4>
            <SpeechBubble
                style={{
                    justifyContent: 'left',
                    marginTop: 10,
                    marginBottom: 20,
                }}
            >
                Set Min/Max to -1 for all packs except bundles. For
                strikethrough price set min/max to 1000.
            </SpeechBubble>
            {pack.priceLevels.map((price, priceIndex) => {
                const isOpen = expandedPrice === priceIndex

                return (
                    <div key={`price${priceIndex}`} style={{ marginBottom: 5 }}>
                        <PriceIdExpandButton
                            isOpen={isOpen}
                            onClick={() => {
                                setExpandedPrice(isOpen ? null : priceIndex)
                            }}
                        />
                        <div className="labelInput">
                            {' '}
                            {/* skip LabelInput component to make focus field on new easier */}
                            {priceIndex === 0 && (
                                <label htmlFor={`product-id-${priceIndex}`}>
                                    Product ID/SKU
                                </label>
                            )}
                            <input
                                id={`product-id-${priceIndex}`}
                                className="labelInput semiwide"
                                style={{ marginBottom: 0 }}
                                value={pack.priceLevels[priceIndex].productId}
                                ref={priceInput}
                                onChange={(e) =>
                                    onPriceLevelSkuChange(e, priceIndex)
                                }
                            ></input>
                        </div>
                        <LabelInput
                            className="prices"
                            size="tiny"
                            type="number"
                            tooltip="Min unowned locations to get this price."
                            name={PossibleInputFieldsNames.Min}
                            label={priceIndex === 0 ? 'min' : ''}
                            value={price.min}
                            onChange={(e) => onInputFieldChange(e, priceIndex)}
                        />
                        <LabelInput
                            className="prices"
                            size="tiny"
                            type="number"
                            tooltip="Max unowned locations to get this price."
                            name={PossibleInputFieldsNames.Max}
                            value={price.max}
                            label={priceIndex === 0 ? 'max' : ''}
                            onChange={(e) => onInputFieldChange(e, priceIndex)}
                        />
                        <LabelInput
                            className="prices"
                            size="tiny"
                            type="number"
                            inputMode="decimal"
                            tooltip="Price for the store front export."
                            name={PossibleInputFieldsNames.Price}
                            value={price.price}
                            label={priceIndex === 0 ? 'Price' : ''}
                            onChange={(e) => onInputFieldChange(e, priceIndex)}
                        />
                        <PriceIdDeleteButton
                            onClick={() => {
                                deletePriceId(priceIndex)
                            }}
                        />

                        <table
                            style={{
                                display: isOpen ? 'block' : 'none',
                                marginTop: 10,
                                marginBottom: 20,
                            }}
                        >
                            <thead>
                                <tr>
                                    <th>Active</th>
                                    <th style={{ width: 87 }}>Platform</th>
                                    <th>Sku</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {price.platformData.map(
                                    (platformData, platformDataIndex) => {
                                        return (
                                            <tr
                                                className="platformData"
                                                key={`platformData${priceIndex}_${platformDataIndex}`}
                                            >
                                                <td>
                                                    <input
                                                        className="labelInput prices tiny"
                                                        type="checkbox"
                                                        checked={
                                                            platformData.active
                                                        }
                                                        name="active"
                                                        onChange={(event) => {
                                                            onPlatformDataChange(
                                                                event,
                                                                priceIndex,
                                                                platformDataIndex
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        fontFamily: 'standard',
                                                    }}
                                                >
                                                    {platformData.store}
                                                </td>
                                                <td>
                                                    <input
                                                        className="labelInput prices semiwide"
                                                        type="text"
                                                        value={platformData.sku}
                                                        name="sku"
                                                        onChange={(event) => {
                                                            onPlatformDataChange(
                                                                event,
                                                                priceIndex,
                                                                platformDataIndex
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                >
                                                    <button
                                                        className="small"
                                                        style={{
                                                            marginRight: 10,
                                                        }}
                                                        onClick={(e) =>
                                                            archiveSKU(
                                                                e,
                                                                priceIndex,
                                                                platformDataIndex
                                                            )
                                                        }
                                                    >
                                                        Archive
                                                    </button>
                                                    <button
                                                        onClick={(e) =>
                                                            showSKUHistory(
                                                                e,
                                                                priceIndex,
                                                                platformDataIndex
                                                            )
                                                        }
                                                        className="small"
                                                    >
                                                        History
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                )
            })}
            <button style={{ marginTop: 20 }} onClick={onNewPriceIdAdded}>
                Add new
            </button>
        </>
    )
}

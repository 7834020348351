import React, { ChangeEvent } from 'react'

import { LabelInput, NewRow, SpeechBubble } from '../../shared'

import { IapExportStatus } from '../../hooks/packs.ts'

import { Pack } from '../../types/packTypes'

const IapExportStatusToMessage: Record<IapExportStatus, string> = {
    [IapExportStatus.Exporting]:
        'Exporting In-App Purchases to Apple. Please wait...',
    [IapExportStatus.Exported]: 'Done exporting!',
    [IapExportStatus.PartiallyExported]: 'Some IAPs failed to export.',
    [IapExportStatus.Failed]: 'An error occured while exporting',
    [IapExportStatus.NotSet]: '',
}

export enum PossibleOverviewFields {
    Name = 'name',
    PackId = 'packId',
    ActiveFromDate = 'activeFromDate',
    ActiveToDate = 'activeToDate',
    Worth = 'worth',
    RequiredVersion = 'requiredVersion',
}

type PackOverviewProps = {
    pack: Pack
    onInputChange: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        child?: string | null,
        index?: number | null
    ) => void
    onExportToAppleStoreClick: () => void
    isNew: boolean
    exportIapStatus: IapExportStatus
}

export function PackOverview(props: PackOverviewProps) {
    const {
        pack,
        isNew,
        onInputChange,
        onExportToAppleStoreClick,
        exportIapStatus,
    } = props

    if (!pack.type) {
        return null
    }

    return (
        <div>
            <LabelInput
                size="wide"
                name={PossibleOverviewFields.Name}
                value={pack.name}
                tooltip="Used for import of localized pack text."
                label="String ID"
                onChange={onInputChange}
            />
            <LabelInput
                disabled={!isNew}
                size="wide"
                name={PossibleOverviewFields.PackId}
                value={pack.packId}
                tooltip="Should match Pack ID in the app, something like: pack.[playsetname]"
                label="pack id"
                onChange={onInputChange}
            />
            <NewRow />
            <LabelInput
                size="normal"
                type="datetime-local"
                name={PossibleOverviewFields.ActiveFromDate}
                value={pack.activeFromDate}
                label="Starts"
                onChange={onInputChange}
            />
            <LabelInput
                size="normal"
                type="datetime-local"
                name={PossibleOverviewFields.ActiveToDate}
                value={pack.activeToDate}
                label="Ends"
                onChange={onInputChange}
            />
            <NewRow />
            <LabelInput
                size="narrow"
                name={PossibleOverviewFields.Worth}
                value={pack.worth}
                tooltip="Price tier for the pack. Set to 0 for bundles"
                label="worth"
                onChange={onInputChange}
                type="number"
            />
            <LabelInput
                size="narrow"
                name={PossibleOverviewFields.RequiredVersion}
                value={pack.requiredVersion}
                tooltip="Shop version of the release when the pack is first available. Disables purchase in older releases."
                label="required shop version"
                onChange={onInputChange}
                type="number"
            />
            <NewRow />
            {exportIapStatus === IapExportStatus.NotSet ? (
                <button onClick={onExportToAppleStoreClick}>
                    Export to Apple Store
                </button>
            ) : (
                <SpeechBubble
                    style={{
                        justifyContent: 'left',
                    }}
                >
                    {IapExportStatusToMessage[exportIapStatus]}
                </SpeechBubble>
            )}
        </div>
    )
}

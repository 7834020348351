import React, { SelectHTMLAttributes } from 'react'
import classNames from 'classnames'

import styles from './Selector.module.scss'

type SelectorProps = SelectHTMLAttributes<HTMLSelectElement>

function Selector(props: SelectorProps) {
    const { className, children, ...selectAttributes } = props
    return (
        <select
            {...selectAttributes}
            className={classNames(className, styles.selector)}
        >
            {children}
        </select>
    )
}

export default Selector

import React from 'react'

import checkedImg from '../assets/checked.png'
import unCheckedImg from '../assets/unchecked.png'

function Checkbox(props) {
    const { label, checked, pack, highlight, isSearching, disabled } = props

    let labelStyle = {}
    if (highlight) {
        labelStyle.color = '#FFAE53'
        labelStyle.fontWeight = 700
    }

    let isTonedDown = isSearching && !highlight

    if (disabled) isTonedDown = true

    function click() {
        if (disabled && !checked) return

        props.onToggle(pack)
    }

    return (
        <div
            onClick={click}
            style={{
                display: 'inline-block',
                marginBottom: 5,
                cursor: 'pointer',
                opacity: isTonedDown ? 0.3 : 1,
            }}
        >
            <img
                alt="delete"
                style={{ width: 20, marginBottom: '-5px', marginRight: '5px' }}
                src={checked ? checkedImg : unCheckedImg}
            ></img>
            <label style={labelStyle}>{label}</label>
        </div>
    )
}

export default Checkbox

import { useState } from 'react'
import axios, { isAxiosError } from 'axios'

import { MediaType } from '../types/enums/mediatype.enum'

import { Media } from '../types/mediaTypes'

export type SortByOption = 'type' | 'url' | 'created'
export type Order = 'asc' | 'desc'

export type ListMediasParams = {
    page?: number
    limit?: number
    sortBy?: SortByOption
    order?: Order
    filterByType?: MediaType[]
    filterByDateFrom?: string
    filterByDateTo?: string
}

type MediasResponse = {
    medias: Media[]
    pagination: {
        page: number
        limit: number
        totalPageCount: number
    }
}

export function useMedias() {
    const [mediasResponse, setMedias] = useState<MediasResponse | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    async function listMedias(params?: ListMediasParams) {
        setMedias(undefined)
        setIsLoading(true)
        setError(undefined)
        try {
            const { data } = await axios.get('/api/v2/medias', { params })
            setMedias(data)
        } catch (err: unknown) {
            if (err instanceof Error) {
                setError(err.message)
            }
            if (isAxiosError(err)) {
                setError(err.response?.data.message ?? err.message)
            }
        }
        setIsLoading(false)
    }

    return {
        mediasResponse,
        fetch: listMedias,
        isLoading,
        error,
    }
}

import React, { ChangeEvent } from 'react'
import { Tooltip } from 'react-tooltip'

import { packTypeToDisplayText } from '../../settings'

import { PackType } from '../../types/enums/packtype.enum.ts'

type PackTypeSelectorProps = {
    packType: PackType
    onTypeChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export function PackTypeSelector(props: PackTypeSelectorProps) {
    const { packType, onTypeChange } = props
    const isReadonlyType = [PackType.STANDALONE, PackType.PACK].includes(
        packType
    )

    return (
        <div>
            <h2>What kind of pack is this?</h2>

            <label
                data-tooltip-content="A bundle can contain all other packs: playsets and creator packs"
                data-tooltip-id="multipack"
            >
                <input
                    type="radio"
                    value={PackType.MULTIPACK}
                    name="type"
                    checked={packType === PackType.MULTIPACK}
                    onChange={onTypeChange}
                    disabled={isReadonlyType}
                />
                {packTypeToDisplayText[PackType.MULTIPACK]}
            </label>
            <Tooltip delayShow={500} place="bottom" id="multipack" />

            <label
                data-tooltip-content="A playset contains scenes and additional content (features)"
                data-tooltip-id="playset"
            >
                <input
                    type="radio"
                    value={PackType.PLAYSET}
                    name="type"
                    checked={packType === PackType.PLAYSET}
                    onChange={onTypeChange}
                    disabled={isReadonlyType}
                />
                {packTypeToDisplayText[PackType.PLAYSET]}
            </label>
            <Tooltip delayShow={500} place="bottom" id="playset" />

            <label
                data-tooltip-content="Contains home designer and furniture items"
                data-tooltip-id="home_designer"
            >
                <input
                    type="radio"
                    value={PackType.HOME_DESIGNER}
                    name="type"
                    checked={packType === PackType.HOME_DESIGNER}
                    onChange={onTypeChange}
                    disabled={isReadonlyType}
                />
                {packTypeToDisplayText[PackType.HOME_DESIGNER]}
            </label>
            <Tooltip delayShow={500} place="bottom" id="home_designer" />

            {/* { Hide Outfit maker packs because it's launch in the app was postponed } */}
            {/*<label
                data-tooltip-content="Contains outfit maker items"
                data-tooltip-id="outfit_maker"
            >
                <input
                    type="radio"
                    value={PackType.OUTFIT_MAKER}
                    name="type"
                    checked={packType === PackType.OUTFIT_MAKER}
                    onChange={onTypeChange}
                    disabled={isReadonlyType}
                />
                Outfit Maker
            </label>
            <Tooltip delayShow={500} place="bottom" id="outfit_maker" />*/}

            <label
                data-tooltip-content="A character creator pack contains style items"
                data-tooltip-id="character_creator"
            >
                <input
                    type="radio"
                    value={PackType.CHARACTER_CREATOR}
                    name="type"
                    checked={packType === PackType.CHARACTER_CREATOR}
                    onChange={onTypeChange}
                    disabled={isReadonlyType}
                />
                {packTypeToDisplayText[PackType.CHARACTER_CREATOR]}
            </label>
            <Tooltip delayShow={500} place="bottom" id="character_creator" />

            {isReadonlyType && (
                <>
                    <label
                        data-tooltip-content="This kind of packs are deprecated and any changes are restricted"
                        data-tooltip-id={packType}
                    >
                        <input
                            type="radio"
                            value={packType}
                            name="type"
                            checked
                            disabled
                        />
                        <span style={{ textTransform: 'capitalize' }}>
                            {packType === PackType.PACK ? 'District' : packType}
                        </span>
                    </label>
                    <Tooltip delayShow={500} place="bottom" id={packType} />
                </>
            )}
        </div>
    )
}

import React from 'react'

import ABTestTool from './ABTestTool'
import ShopConfiguration from './ShopConfiguration'
import { Section } from '../shared'

const Tools = () => {
    return (
        <>
            <Section title="Shop A/B Testing Configuration">
                <ABTestTool />
            </Section>
            <Section title="Shop Remote Config Configuration">
                <ShopConfiguration />
            </Section>
        </>
    )
}

export default Tools

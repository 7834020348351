import React from 'react'
import { createRoot } from 'react-dom/client'
import axios from 'axios'

import App from './App'

import registerServiceWorker from './registerServiceWorker'

import './index.scss'

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)
root.render(<App />)

// Axios send credentials cross site
axios.defaults.withCredentials = true

/* Uncomment below to turn of hot reloading */
/*if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    root.render(<App />)
  })
} */

window.version = 4711

registerServiceWorker()

import React from 'react'
import { AxiosResponse } from 'axios'

import { DraggablePhotos } from '../shared'

import {
    ImageFileUploading,
    onSelectPhoto,
    OptionalData,
} from '../utils/upload'

import {
    Dimensions,
    iapImageDimensions,
    packScrollingScreenshotDimensions,
    packTabThumbnailDimensions,
    packThumbnailDimensions,
    packWideThumbnailDimensions,
} from '../settings'

import { MediaType } from '../types/enums/mediatype.enum'

import { Media } from '../types/mediaTypes'
import { Pack } from '../types/packTypes'

import wormImg from '../assets/worm.png'

type ThumbnailProps = {
    type: MediaType
    id: Pack['packId']
    items: (Media | (ImageFileUploading & { id?: number }))[]
    onSelect: (
        type: MediaType,
        items: ImageFileUploading[],
        optionalData: OptionalData
    ) => void
    onUpload: (
        type: MediaType,
        file: File,
        res: AxiosResponse<{ uploaded: { id: number } }>
    ) => void
    onDelete: (index: number, type: MediaType) => void
    onDragPhotoEnd: (result: {
        source: { index: number; droppableId: MediaType }
        destination: { index: number }
    }) => void
    fileName: string
    isMultiple: boolean
}

function Thumbnail(props: ThumbnailProps) {
    const {
        items,
        id,
        type,
        onSelect,
        onUpload,
        onDelete,
        isMultiple,
        fileName,
        onDragPhotoEnd,
    } = props

    let expectedSize: Dimensions | Dimensions[] = packThumbnailDimensions
    switch (type) {
        case MediaType.THUMBNAIL:
            expectedSize = packThumbnailDimensions
            break
        case MediaType.TAB_THUMBNAIL:
            expectedSize = packTabThumbnailDimensions
            break
        case MediaType.SCROLLING_SCREENSHOT:
            expectedSize = packScrollingScreenshotDimensions
            break
        case MediaType.IAP_IMAGE:
            expectedSize = iapImageDimensions
            break
        default:
            expectedSize = packWideThumbnailDimensions
            break
    }

    return (
        <div>
            {items.length === 0 && (
                <div className="noMediaWrapper">
                    <img
                        alt="placeholder no screenshots uploaded"
                        src={wormImg}
                    ></img>
                    <label className="placeholder">
                        No {type == MediaType.IAP_IMAGE ? 'image' : 'thumbnail'}
                        , just yet. Drag and drop here to upload :)
                    </label>
                </div>
            )}
            <DraggablePhotos
                onDelete={onDelete}
                type={type}
                items={items}
                onDragEnd={onDragPhotoEnd}
            />
            <div style={{ marginTop: 20 }}>
                <input
                    type="file"
                    name=""
                    id={id}
                    accept="Image/*"
                    onChange={(e) =>
                        onSelectPhoto(e, type, onSelect, onUpload, {
                            fileName,
                            expectedSize,
                        })
                    }
                    multiple={isMultiple}
                />
                <label className="upload" htmlFor={id}>
                    Add new
                </label>
            </div>
        </div>
    )
}

export default Thumbnail

import Joi from 'joi'

import type { Gift } from './giftTypes'

const giftErrorSchema = Joi.object()
    .options({ abortEarly: false })
    .keys({
        giftId: Joi.string().required(),
        requiredVersion: Joi.number().required(),
        releaseNumber: Joi.any(),
        startDate: Joi.date()
            .required()
            .messages({ 'date.base': '"Starts on" date must be selected' })
            .when('endDate', {
                is: Joi.date().not().empty(),
                then: Joi.date().less(Joi.ref('endDate')).messages({
                    'date.less': '"Starts on" date must be before "Ends on"',
                }),
            }),
        startDateCopy: Joi.any(),
        endDate: Joi.date()
            .required()
            .messages({ 'date.base': '"Ends on" date must be selected' })
            .when('startDateCopy', {
                is: Joi.date().not().empty(),
                then: Joi.date().greater(Joi.ref('startDateCopy')).messages({
                    'date.greater': '"Ends on" date must be after "Starts on"',
                }),
            }),
        active: Joi.any(), // calculated by server, cms does nothing with it
    })

const giftWarningSchema = Joi.object().options({ abortEarly: false }).keys({
    giftId: Joi.string().required(),
    requiredVersion: Joi.number().required(),
    releaseNumber: Joi.any(),
    startDate: Joi.date().required(),
    startDateCopy: Joi.any(),
    endDate: Joi.date().required(),
    active: Joi.any(), // calculated by server, cms does nothing with it
})

function validate(item: Gift) {
    const validationItem = {
        ...item,
        startDateCopy: item.startDate, // startDateCopy is added to avoid circular dependencies when validating startDate and endDate
    }
    const warnings = giftWarningSchema.validate(validationItem)
    const errors = giftErrorSchema.validate(validationItem)
    return { errors, warnings }
}

export default validate

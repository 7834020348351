import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AxiosResponse } from 'axios'

import { DragDropContainer, DraggablePhotos } from '../../shared'

import { onSelectVideo, VideoFileUploading } from '../../utils/upload'

import { MediaType } from '../../types/enums/mediatype.enum.ts'

import { Media } from '../../types/mediaTypes'
import { Pack } from '../../types/packTypes'

import dogImg from '../../assets/dog.png'

type PackVideosSectionProps = {
    pack: Pack
    items: (Media | (VideoFileUploading & { id?: number }))[]
    onVideoSelected: (item: VideoFileUploading) => void
    onVideoWasUploaded: (
        file: File,
        res: AxiosResponse<{ uploaded: { id: number }[] }>
    ) => void
    onVideoDelete: (index: number, type: MediaType) => void
    onVideoDragEnd: (result: {
        source: { index: number; droppableId: MediaType }
        destination: { index: number }
    }) => void
}

export const PackVideosSection = (props: PackVideosSectionProps) => {
    const {
        pack,
        items,
        onVideoSelected,
        onVideoWasUploaded,
        onVideoDelete,
        onVideoDragEnd,
    } = props

    return (
        <DndProvider backend={HTML5Backend}>
            <DragDropContainer
                data={pack}
                type={MediaType.VIDEO}
                onMediasSelected={onVideoSelected}
                onMediasWasUploaded={onVideoWasUploaded}
            >
                <>
                    <h3>Videos</h3>
                    {items.length === 0 && (
                        <div className="noMediaWrapper">
                            <img
                                alt="placeholder no uploaded video"
                                src={dogImg}
                            ></img>
                            <label className="placeholder">
                                No video? Ohno. Drag and drop here to upload :)
                            </label>
                        </div>
                    )}
                    <DraggablePhotos
                        onDelete={onVideoDelete}
                        type={MediaType.VIDEO}
                        items={items}
                        onDragEnd={onVideoDragEnd}
                    />

                    <div style={{ marginTop: 20 }}>
                        <input
                            type="file"
                            name=""
                            id="videos"
                            accept="video/*"
                            onChange={(e) =>
                                onSelectVideo(
                                    e,
                                    onVideoSelected,
                                    onVideoWasUploaded
                                )
                            }
                        />
                        <label className="upload" htmlFor="videos">
                            Add new
                        </label>
                    </div>
                </>
            </DragDropContainer>
        </DndProvider>
    )
}

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'

import { LabelInput, Loading, Modal, Section } from '../shared'

import { formatDate } from '../utils/dates'
import { blockedVersionTypes, handleError, platforms } from '../utils/utils'

const Versions = function () {
    const emptyFormObj = {
        platform: Object.keys(platforms)[0],
        version: '',
        type: Object.keys(blockedVersionTypes)[0],
        comment: '',
    }

    const [hasLoaded, setHasLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [liveVersions, setLiveVersions] = useState([])
    const [draftVersions, setDraftVersions] = useState({})
    const [blockedVersions, setBlockedVersions] = useState([])
    const [emptyBlockVersion, setEmptyBlockedVersion] = useState(emptyFormObj)

    const fetchBlockedVersions = async () => {
        setHasLoaded(true)
        let response = await axios.get('/api/v2/blockedversions')
        setBlockedVersions(response.data.blockedVersions)
    }

    const fetchLiveVersions = async () => {
        const { data } = await axios.get('/api/v2/liveversions')
        setLiveVersions(_.sortBy(data, 'platform'))
    }

    useEffect(() => {
        if (!hasLoaded) fetchBlockedVersions()
    })

    useEffect(() => {
        fetchLiveVersions()
    }, [])

    const onInputChange = (e) => {
        let data = _.cloneDeep(emptyBlockVersion)
        data[e.target.name] = e.target.value
        setEmptyBlockedVersion(data)
    }

    const unblock = async (e, data) => {
        e.stopPropagation()

        let isConfirmed = await Modal.confirm({
            heading: 'Unblock a version',
            text: `Really want to unblock ${
                blockedVersionTypes[data.type]
            } version ${data.version} for ${data.platform}?`,
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        setIsLoading(true)

        try {
            await axios.delete('/api/v2/unblockversion/', { data })

            setHasLoaded(false)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            handleError('Error unblocking version', e)
        }
    }

    const onLiveVersionChange = (e) => {
        setDraftVersions({
            ...draftVersions,
            [e.target.name]: e.target.value,
        })
    }

    const onSaveLiveVersions = async (e) => {
        e.preventDefault()

        const newVersions = Object.entries(draftVersions).map(
            ([platform, version]) => ({ platform, version })
        )
        const updateLiveVersionPromises = newVersions.map((newVersion) =>
            axios.put('/api/v2/liveversion', newVersion)
        )

        const result = await Promise.allSettled(updateLiveVersionPromises)

        const errors = result
            .filter(({ status }) => status === 'rejected')
            .map(({ reason }) => reason.response.data.message)
        if (errors.length) {
            handleError('Error while updating live version(s)', errors)
        }

        const updatedVersions = result
            .filter(({ status }) => status === 'fulfilled')
            .map(({ value }) => value.data)

        const newLiveVersions = _.unionBy(
            updatedVersions,
            liveVersions,
            'platform'
        )
        setLiveVersions(_.sortBy(newLiveVersions, 'platform'))
        setDraftVersions({})
    }

    const save = async () => {
        let data = _.cloneDeep(emptyBlockVersion)
        setIsLoading(true)
        try {
            if (data.platform === 'all') {
                for (const platform in platforms) {
                    await axios.post('/api/v2/blockversion/', {
                        ...data,
                        platform,
                    })
                }
            } else {
                await axios.post('/api/v2/blockversion/', data)
            }
            setHasLoaded(false)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            handleError(
                `Error while blocking version "${data.version}" for ${data.platform}`,
                error
            )
        }
        setEmptyBlockedVersion(emptyFormObj)
    }

    const hasDraftChanges = liveVersions.some(
        ({ platform, version }) =>
            Object.hasOwn(draftVersions, platform) &&
            draftVersions[platform] !== version
    )

    return (
        <>
            <Section title="Live versions">
                <form onSubmit={onSaveLiveVersions}>
                    {liveVersions.map((version) => (
                        <LabelInput
                            key={version.platform}
                            size="small"
                            label={version.platform}
                            name={version.platform}
                            value={
                                draftVersions[version.platform] ??
                                version.version
                            }
                            title="A minor version (1.0) or a patch version (1.0.0)"
                            pattern="\d+\.\d+(\.\d)?"
                            onChange={onLiveVersionChange}
                        />
                    ))}
                    <button disabled={!hasDraftChanges} type="submit">
                        Save
                    </button>
                </form>
            </Section>
            <Section title="Block new version">
                <LabelInput
                    object={emptyBlockVersion}
                    size="small"
                    name="version"
                    label="Version to block"
                    onChange={(e) => onInputChange(e)}
                />
                <select
                    className="blurrable"
                    name="platform"
                    value={emptyBlockVersion.platform}
                    onChange={(e) => onInputChange(e)}
                >
                    {Object.keys(platforms).map((item, key) => {
                        return (
                            <option key={key} value={item}>
                                {platforms[item]}
                            </option>
                        )
                    })}
                    <option value="all">All platforms</option>
                </select>
                <select
                    className="blurrable"
                    name="type"
                    value={emptyBlockVersion.type}
                    onChange={(e) => onInputChange(e)}
                >
                    {Object.keys(blockedVersionTypes).map((item, key) => {
                        return (
                            <option key={key} value={item}>
                                {blockedVersionTypes[item]}
                            </option>
                        )
                    })}
                </select>
                <LabelInput
                    object={emptyBlockVersion}
                    size="wide"
                    name="comment"
                    label="Comment"
                    onChange={(e) => onInputChange(e)}
                />
                <button
                    onClick={save}
                    disabled={emptyBlockVersion.version === ''}
                >
                    Save
                </button>
            </Section>
            <Section title="Blocked Shop/App versions">
                {isLoading && <Loading text="Loading..." />}
                {!isLoading && blockedVersions && (
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Version</th>
                                <th style={{ textAlign: 'left' }}>
                                    <label
                                        htmlFor="name"
                                        style={{ marginRight: 0 }}
                                    >
                                        Platform
                                    </label>
                                </th>
                                <th>Type</th>
                                <th style={{ textAlign: 'center' }}>Comment</th>
                                <th style={{ textAlign: 'right' }}>
                                    Blocked On
                                </th>
                                <th style={{ textAlign: 'right' }}>
                                    <label
                                        htmlFor="delete"
                                        style={{ marginRight: 0 }}
                                    ></label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {blockedVersions.map((item, k) => {
                                item.created = formatDate(item.created)
                                return (
                                    <tr key={'id' + k}>
                                        <td>{item.version}</td>
                                        <td>{platforms[item.platform]}</td>
                                        <td>
                                            {blockedVersionTypes[item.type]}
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            {item.comment}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            {item.created}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            <button
                                                style={{ margin: '0 3px' }}
                                                className="small"
                                                onClick={(e) =>
                                                    unblock(e, item)
                                                }
                                            >
                                                Unblock
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}
            </Section>
        </>
    )
}

export default Versions

import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'

import { Loading, Modal, NewRow, Section } from '../shared'

import { handleEnvAuth } from '../utils/envaccess'
import {
    fetchDataFromPath,
    postDataForPath,
    putDataForPath,
} from '../utils/envcopy'
import { copyMedias } from '../utils/upload'
import { handleError } from '../utils/utils'
import withRouter from '../utils/withRouter'

class Maps extends Component {
    state = {
        data: [],
        isLoading: true,
        loadingMessage: '',
        uploadDetails: {},
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        try {
            let response = await axios.get('/api/maps')
            console.log('got maps', response.data)
            if (!response.data.error)
                this.setState({ data: response.data, isLoading: false })
        } catch (e) {
            handleError('Error loading packs', e)
        }
    }

    activate = async (e, i) => {
        e.stopPropagation()
        let data = _.cloneDeep(this.state.data)

        let isConfirmed = await Modal.confirm({
            heading: 'Activate map',
            text: 'Set map "' + data[i].mapName + '" to active?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        data.map((item) => (item.active = false)) // inactivate all
        data[i].active = true
        this.setState({ data })

        // save the new active map, server will inactivate others

        try {
            await axios.put('/api/maps', { data: data[i] })
            console.log('Saved active map!')
        } catch (e) {
            handleError('Error saving active map', e)
        }
    }

    rename = async (e, i) => {
        e.stopPropagation()
        let data = _.cloneDeep(this.state.data)

        let name = await Modal.prompt({
            heading: 'Rename atlas',
            text: 'New name:',
        })
        if (!name) return

        const payload = {
            mapId: data[i].mapId,
            mapName: name,
        }

        try {
            await axios.put('/api/maps/rename', { data: payload })

            console.log('Saved renamed map!')

            data[i].mapName = name
            this.setState({ data })
        } catch (e) {
            handleError('Error saving active map', e)
        }
    }

    duplicate = async (e, i) => {
        e.stopPropagation()
        let name = await Modal.prompt({
            heading: 'Duplicate atlas',
            text: 'Name the new atlas:',
        })
        if (!name) return

        let data = _.cloneDeep(this.state.data[i])
        data.mapName = name
        console.log('Saving copy of map, new name is', name)

        try {
            await axios.post('/api/maps', { data })
            console.log('Map copy saved successfully!')
            this.init()
        } catch (e) {
            handleError('Error saving pack', e)
        }
    }

    onMediaUploadCompleteCallback = (res, success) => {
        // TODO: Maybe handle response here...
        console.log('Media upload success:', success)
        console.log('Media upload response:', res)
        const { current, max } = this.state.uploadDetails
        const newCounterValue = current + 1 <= max ? current + 1 : current
        this.setState({
            uploadDetails: { current: newCounterValue, max: max },
            loadingMessage: `Uploading media file ${newCounterValue} of ${max}...`,
        })
    }

    prepareMapContent = async (map, env) => {
        // Prepare map content and validate packIds
        let images = []
        let missingPacks = []
        for (const item of map.items) {
            // Validate item pack (packId is allowed to be empty)
            if (item.packId) {
                const fetchedData = await fetchDataFromPath(
                    `${env.basepath}/v2/pack/${item.packId}`
                )
                if (_.isEmpty(fetchedData.pack)) missingPacks.push(item.packId)
            }

            // Get map background
            images.push({
                mediaId: item.background.mediaId,
                url: item.background.url,
                md5: item.background.md5,
                type: 'mapbackground',
                property: 'background',
            })
            // Get location images
            for (const location of item.locations) {
                // Validate location pack
                const fetchedData = await fetchDataFromPath(
                    `${env.basepath}/v2/pack/${location.packId}`
                )
                if (_.isEmpty(fetchedData.pack))
                    missingPacks.push(location.packId)

                images.push({
                    mediaId: location.img.mediaId,
                    url: location.img.url,
                    md5: location.img.md5,
                    type: 'maplocation',
                    property: location.packId,
                })
            }
        }

        if (missingPacks.length > 0) {
            const missingPacksInfo = missingPacks
                .filter((pack, index) => missingPacks.indexOf(pack) === index)
                .join(', ')
            await Modal.alert({
                heading: 'Missing packs!',
                text: `Oh no, some packs wasn't found in the target environment, please add them and try again: ${missingPacksInfo}`,
            })
            return false
        }

        // Confirm copy of images
        const isConfirmed = await Modal.confirm({
            heading: 'Confirm',
            text: `Media will be copied to ${env.name}, do you want to continue?`,
            okLabel: 'Copy',
        })
        if (!isConfirmed) return false

        const uploadDetails = {
            current: 1,
            max: images.length,
        }
        this.setState({
            uploadDetails: uploadDetails,
            loadingMessage: `Uploading media file ${uploadDetails.current} of ${uploadDetails.max}...`,
        })
        const mediaIds = await copyMedias(
            images,
            map.mapId,
            env,
            this.onMediaUploadCompleteCallback
        )

        // Iterate map items and set new media ids
        map.items = map.items.map((item) => {
            const background = _.find(mediaIds, (media) => {
                return (
                    media.media.property === 'background' &&
                    media.media.mediaId === item.background.mediaId
                )
            })
            item.background.mediaId = background ? background.mediaId : ''
            item.locations = item.locations.map((location) => {
                let newLocation = _.find(mediaIds, (media) => {
                    return (
                        media.media.type === 'maplocation' &&
                        media.media.property === location.packId &&
                        media.media.mediaId === location.img.mediaId
                    )
                })
                location.img.mediaId = newLocation ? newLocation.mediaId : ''
                return location
            })
            return item
        })

        // Finally, set active state to false
        map.active = false

        return map
    }

    onCopyMapClick = async (e, i) => {
        e.stopPropagation()
        this.setState({ isLoading: true, loadingMessage: 'Copying map...' })
        let data = _.cloneDeep(this.state.data[i])

        const env = await handleEnvAuth()
        if (!env) {
            this.setState({
                isLoading: false,
                loadingMessage: '',
            })
            return
        }

        // Get and compare maps
        const currentMap = await fetchDataFromPath(
            `/api/v2/maps/${data.mapName}`
        )
        const refMap = await fetchDataFromPath(
            `${env.basepath}/v2/maps/${data.mapName}`
        )

        if (_.isEmpty(refMap)) {
            // No map in target environment, show confirm modal
            let isConfirmed = await Modal.confirm({
                heading: 'Copy map',
                text: `No map with name '${data.mapName}' found in ${env.name}, do you want to make a copy?`,
                okLabel: 'Yes do it!',
            })
            if (!isConfirmed) {
                this.setState({
                    isLoading: false,
                    loadingMessage: '',
                })
                return
            }
        } else {
            // Map with the same name already exists, abort
            this.setState({
                isLoading: false,
                loadingMessage: '',
            })
            await Modal.alert({
                heading: 'Map already exists',
                text: `Woops, it looks like a map called '${currentMap.mapName}' already exists in ${env.name}!`,
            })
            return
        }

        // Prepare map content before copy
        this.setState({ loadingMessage: 'Preparing map content...' })
        const map = await this.prepareMapContent(currentMap, env)
        if (!map) {
            this.setState({
                isLoading: false,
                loadingMessage: '',
            })
            return
        }

        // Finally, copy map in target environment
        this.setState({ loadingMessage: `Saving map to ${env.name}...` })
        const hasCopiedSuccessfully = _.isEmpty(refMap)
            ? await postDataForPath({ data: map }, `${env.basepath}/maps`)
            : await putDataForPath(
                  { data: map },
                  `${env.basepath}/maps/${refMap.mapId}`
              )

        this.setState({ isLoading: false })

        if (hasCopiedSuccessfully)
            await Modal.alert({
                heading: 'Copying done!',
                text: `Woho, the map with name '${currentMap.mapName}' was successfully stored in ${env.name}!`,
            })
        else
            await Modal.alert({
                heading: 'On no!',
                text: `Something went wrong when trying to copy map with name '${currentMap.mapName}' to ${env.name}!`,
            })
    }

    delete = async (e, i) => {
        e.stopPropagation()
        let data = _.cloneDeep(this.state.data)
        let name = data[i].mapName

        let isConfirmed = await Modal.confirm({
            heading: 'Delete map',
            text: 'Really delete this map?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        try {
            console.log('Deleting map ', name)
            await axios.delete('/api/maps/' + name)
            data.splice(i, 1)
            this.setState({ data })
        } catch (e) {
            handleError('Error saving pack', e)
        }
    }

    onCreateNew = async () => {
        let name = await Modal.prompt({
            heading: 'New atlas',
            text: 'Name the new atlas:',
        })
        if (!name) return
        this.props.navigate('/map/' + name)
    }

    render() {
        if (this.state.isLoading)
            return <Loading text={this.state.loadingMessage} />

        const data = this.state.data

        return (
            <div>
                <Section>
                    <div style={{ display: 'inline-block' }}>
                        <h1>Atlases</h1>
                        <button
                            className="new withHeading"
                            onClick={this.onCreateNew}
                        >
                            Add new
                        </button>
                    </div>
                    <NewRow />

                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'left' }}>
                                    <label
                                        htmlFor="name"
                                        style={{ marginRight: 0 }}
                                    >
                                        Name
                                    </label>
                                </th>
                                <th style={{ textAlign: 'right' }}>
                                    <label
                                        htmlFor="start"
                                        style={{ marginRight: 0 }}
                                    >
                                        Districts
                                    </label>
                                </th>
                                <th style={{ textAlign: 'right' }}>
                                    <label
                                        htmlFor="end"
                                        style={{ marginRight: 0 }}
                                    >
                                        Playsets
                                    </label>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, i) => (
                                <tr
                                    key={'tr' + i}
                                    onClick={() =>
                                        this.props.navigate(
                                            '/map/' + item.mapName
                                        )
                                    }
                                >
                                    <td style={{ textAlign: 'left' }}>
                                        {item.mapName}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {item.items.length} districts
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {_.sumBy(item.items, (o) => {
                                            return o.locations.length
                                        })}{' '}
                                        playsets
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {!item.active ? (
                                            <button
                                                style={{
                                                    margin: '0 3px',
                                                }}
                                                className="small positive"
                                                onClick={(e) =>
                                                    this.activate(e, i)
                                                }
                                            >
                                                Set to active
                                            </button>
                                        ) : (
                                            <span style={{ marginRight: 10 }}>
                                                Active!
                                            </span>
                                        )}
                                        <button
                                            style={{ margin: '0 3px' }}
                                            className="small warning"
                                            onClick={(e) => this.rename(e, i)}
                                        >
                                            Rename
                                        </button>
                                        <button
                                            style={{ margin: '0 3px' }}
                                            className="small primary"
                                            onClick={(e) =>
                                                this.duplicate(e, i)
                                            }
                                        >
                                            Duplicate
                                        </button>
                                        <button
                                            style={{ margin: '0 3px' }}
                                            className="small"
                                            onClick={(e) =>
                                                this.onCopyMapClick(e, i)
                                            }
                                        >
                                            Copy to...
                                        </button>
                                        <button
                                            style={{ margin: '0 3px' }}
                                            className="small danger"
                                            onClick={(e) => this.delete(e, i)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Section>
            </div>
        )
    }
}

export default withRouter(Maps)

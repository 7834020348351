import React, { useState } from 'react'
import { SketchPicker } from 'react-color'

const ColorPicker = ({ done, heading, text, cancel, cancelLabel, okLabel }) => {
    const [color, setColor] = useState('')
    return (
        <div tabIndex="0" onKeyDown={(e) => e.key === 'Enter' && done(color)}>
            <h1>{heading || 'So what?'}</h1>
            {text && <p>{text}</p>}
            <SketchPicker color={color} onChangeComplete={(c) => setColor(c)} />

            <div style={{ textAlign: 'right' }}>
                <button className="secondary" onClick={() => cancel()}>
                    {cancelLabel || 'Cancel'}
                </button>
                <button
                    className="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() => done(color)}
                >
                    {okLabel || 'OK'}
                </button>
            </div>
        </div>
    )
}

export default ColorPicker

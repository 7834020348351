import React from 'react'
import _ from 'lodash'

import BulkTableRow from './BulkTableRow.tsx'

import type { GiftChangePair } from './giftTypes'

type BulkTableProps = {
    giftChangePairs: GiftChangePair[]
    validationResults: { [giftId: string]: string[] }
}

function BulkTable(props: BulkTableProps) {
    const { giftChangePairs, validationResults } = props

    const creates = giftChangePairs.filter(
        (giftChange) => giftChange.newGift && !giftChange.oldGift
    )
    const updates = giftChangePairs.filter(
        (giftChange) => giftChange.oldGift && giftChange.newGift
    )
    const deletes = giftChangePairs.filter(
        (giftChange) => giftChange.oldGift && !giftChange.newGift
    )
    const sortedCreates = _.sortBy(
        creates,
        (giftChange) => validationResults[giftChange.newGift.giftId]
    )
    const sortedUpdates = _.sortBy(
        updates,
        (giftChange) => validationResults[giftChange.newGift.giftId]
    )

    return (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Release</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {sortedCreates.map(({ newGift }) => (
                    <BulkTableRow
                        key={newGift.giftId}
                        newGift={newGift}
                        validationErrors={validationResults[newGift.giftId]}
                    />
                ))}
                {sortedUpdates.map(({ oldGift, newGift }) => (
                    <BulkTableRow
                        key={oldGift.giftId}
                        oldGift={oldGift}
                        newGift={newGift}
                        validationErrors={validationResults[newGift.giftId]}
                    />
                ))}
                {deletes.map(({ oldGift }) => (
                    <BulkTableRow key={oldGift.giftId} oldGift={oldGift} />
                ))}
            </tbody>
        </table>
    )
}

export default BulkTable

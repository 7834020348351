import React, { ReactNode } from 'react'
import classNames from 'classnames'

import styles from './Section.module.scss'

import foldImg from '../assets/fold.png'

type SectionProps = {
    id?: string
    openSections?: string[]
    title?: string
    titleIcon?: string
    className?: string
    children: ReactNode
    isTransparent?: boolean
    toggleSection?: (id: string) => void
    style?: React.CSSProperties
    contentStyle?: React.CSSProperties
}

function Section(props: SectionProps) {
    const {
        openSections,
        title,
        titleIcon,
        id,
        className,
        children,
        isTransparent,
        toggleSection,
        contentStyle,
    } = props

    /*
        This components open state is controlled globally if opensections is 
        passed as a property. If not it’s always open.
    */
    let isOpen = !openSections || (id && openSections.indexOf(id) > -1)
    if (!id) isOpen = true

    const style = props.style || {}
    if (isTransparent) style.background = 'none'

    return (
        <section
            style={style}
            className={
                className ? classNames(className, styles[className]) : ''
            }
        >
            {title && (
                <div
                    className={classNames(
                        styles.header,
                        !isOpen ? styles.collapsed : ''
                    )}
                    onClick={() => {
                        id && toggleSection && toggleSection(id)
                    }}
                >
                    {titleIcon && (
                        <img
                            className={styles.titleIcon}
                            alt="section-icon"
                            src={titleIcon}
                        ></img>
                    )}
                    <h1>{title}</h1>
                    {openSections && (
                        <img
                            alt="open/close"
                            className={classNames(
                                styles.arrow,
                                isOpen ? styles.up : styles.down
                            )}
                            src={foldImg}
                        ></img>
                    )}
                </div>
            )}
            {isOpen && (
                <div className={styles.content} style={contentStyle || {}}>
                    {children}
                </div>
            )}
        </section>
    )
}

export default Section

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppEventScreenshot } from '@tocaboca/app-store-connect'
import axios from 'axios'
import classNames from 'classnames'
import _ from 'lodash'

import { Loading, NewRow, Section, SortIcon, Validator } from '../shared'

import { compareDates, formatDate, getDate } from '../utils/dates.ts'
import { handleError } from '../utils/utils'

import { InAppEvent } from '../types/inappeventTypes'

import {
    AppEventDateEnum,
    getAppEventDate,
    getAppEventScreenshotImageUrl,
    getAppEventStoreUrl,
} from './appeventfunctions'
import validate from './validate'

import widePlaceholderImg from '../assets/widethumb_placeholder.png'

const statusFilterKey = 'iaeStatusFilter'
const searchStringKey = 'iaeSearchString'

export function filterByStatus(statusFilter: string, iaes: InAppEvent[]) {
    if (statusFilter === 'all') {
        return iaes
    }
    const today = new Date()
    return iaes.filter((iae) => {
        const endDate = getDate(
            getAppEventDate(iae.appEvent, AppEventDateEnum.EVENT_END)
        )
        const startDate = getDate(
            getAppEventDate(iae.appEvent, AppEventDateEnum.EVENT_START)
        )
        if (statusFilter === 'active') {
            return endDate >= today && startDate <= today
        }

        return endDate < today || startDate > today
    })
}

function filterBySearchString(
    searchString: string,
    language: string,
    iaes: InAppEvent[]
) {
    if (!searchString) {
        return iaes
    }

    const loweredSearchString = searchString.toLowerCase()
    return iaes.filter(
        (iae) =>
            iae.appEvent.attributes?.referenceName
                ?.toLowerCase()
                ?.includes(loweredSearchString) ||
            iae.localisations
                ?.find((l) => l.attributes?.locale === language)
                ?.attributes?.name?.toLowerCase()
                ?.includes(loweredSearchString)
    )
}

type ComparatorsType = 'referenceName' | AppEventDateEnum

function sort(
    iaes: InAppEvent[],
    sortBy: ComparatorsType | undefined,
    isSortAscend: boolean
) {
    if (!sortBy) {
        return iaes
    }
    const comparators: {
        [key in ComparatorsType]: (a: InAppEvent, b: InAppEvent) => number
    } = {
        referenceName: (a: InAppEvent, b: InAppEvent) =>
            a.appEvent.attributes?.referenceName?.localeCompare(
                b.appEvent.attributes?.referenceName || '',
                undefined,
                {
                    sensitivity: 'base',
                }
            ) || 0,
        [AppEventDateEnum.PUBLISH_START]: (a: InAppEvent, b: InAppEvent) =>
            compareDates(
                getAppEventDate(a.appEvent, AppEventDateEnum.PUBLISH_START),
                getAppEventDate(b.appEvent, AppEventDateEnum.PUBLISH_START)
            ),
        [AppEventDateEnum.EVENT_START]: (a: InAppEvent, b: InAppEvent) =>
            compareDates(
                getAppEventDate(a.appEvent, AppEventDateEnum.EVENT_START),
                getAppEventDate(b.appEvent, AppEventDateEnum.EVENT_START)
            ),
        [AppEventDateEnum.EVENT_END]: (a: InAppEvent, b: InAppEvent) =>
            compareDates(
                getAppEventDate(a.appEvent, AppEventDateEnum.EVENT_END),
                getAppEventDate(b.appEvent, AppEventDateEnum.EVENT_END)
            ),
    }
    const sorted = iaes.slice().sort(comparators[sortBy])

    if (isSortAscend) {
        return sorted
    }
    return sorted.reverse()
}

type InAppEventsProps = {
    language: string
}

function InAppEvents(props: InAppEventsProps) {
    const [iaes, setIaes] = useState<InAppEvent[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [statusFilter, setStatusFilter] = useState(
        localStorage.getItem(statusFilterKey) ?? 'all'
    )
    const [searchString, setSearchString] = useState(
        localStorage.getItem('iaeSearchString') ?? ''
    )
    const [isSortAscend, setIsSortAscend] = useState(false)
    const [sortBy, setSortBy] = useState<ComparatorsType>()
    const navigate = useNavigate()

    const images: Record<string, AppEventScreenshot | null> = {}
    for (const iae of iaes) {
        // Find an image to show in list
        images[iae.appEvent.id] =
            iae.screenshots && iae.screenshots.length > 0
                ? iae.screenshots[0]
                : null
    }

    async function getAllInAppEvents() {
        setIsLoading(true)
        try {
            const { data } = await axios.get<InAppEvent[]>('/api/v2/iaes')
            setIaes(data)
            setIsLoading(false)
        } catch (err) {
            handleError('Error fetching In-App Events', err)
        }
    }

    useEffect(() => {
        getAllInAppEvents()
    }, [])

    const filter: (iaes: InAppEvent[]) => InAppEvent[] = _.flow([
        (iaes) => filterBySearchString(searchString, props.language, iaes),
        (iaes) => filterByStatus(statusFilter, iaes),
    ])

    function onStatusFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        localStorage.setItem(statusFilterKey, value)
        setStatusFilter(value)
    }

    function onSearchInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        localStorage.setItem(searchStringKey, value)
        setSearchString(value)
    }

    function onClickSort(newSortBy: ComparatorsType) {
        if (sortBy === newSortBy) {
            if (isSortAscend) {
                setSortBy(undefined)
            }
            return setIsSortAscend(!isSortAscend)
        }
        setSortBy(newSortBy)
    }

    function makeRow(iae: InAppEvent) {
        const { appEvent } = iae
        const now = new Date()
        const eventStart = getAppEventDate(
                appEvent,
                AppEventDateEnum.EVENT_START
            ),
            eventEnd = getAppEventDate(appEvent, AppEventDateEnum.EVENT_END),
            publishStart = getAppEventDate(
                appEvent,
                AppEventDateEnum.PUBLISH_START
            )
        const inactive =
            !eventStart ||
            !eventEnd ||
            now < new Date(eventStart) ||
            now > new Date(eventEnd)
                ? 'inactive'
                : ''

        return (
            <tr
                onClick={() => navigate(`/iae/${appEvent.id}`)}
                key={appEvent.id}
            >
                <td>
                    <img
                        alt="image"
                        className="thumb"
                        src={
                            getAppEventScreenshotImageUrl(
                                images[appEvent.id],
                                128,
                                72,
                                'jpeg'
                            ) || widePlaceholderImg
                        }
                    />
                </td>
                <td className="alignLeft">
                    {appEvent.attributes?.referenceName}
                </td>
                <td className={classNames('alignRight', inactive)}>
                    {publishStart ? formatDate(publishStart) : 'not set'}
                </td>
                <td className={classNames('alignRight', inactive)}>
                    {eventStart ? formatDate(eventStart) : 'not set'}
                </td>
                <td className={classNames('alignRight', inactive)}>
                    {eventEnd ? formatDate(eventEnd) : 'not set'}
                </td>
                <td className="alignRight">
                    <a
                        href={getAppEventStoreUrl(iae)}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <button
                            className="small"
                            onClick={(e) => e.stopPropagation()}
                        >
                            View on App Store C.
                        </button>
                    </a>
                </td>
                <td className="alignRight validate">
                    <Validator
                        id={appEvent.id}
                        height={23}
                        validationResults={validate(iae, false)}
                    />
                </td>
            </tr>
        )
    }

    if (isLoading) return <Loading />

    const filteredIaes = filter(iaes)
    const sortedIaes = sort(filteredIaes, sortBy, isSortAscend)

    return (
        <Section>
            <div id="controlsPanel" style={{ float: 'right' }}>
                <input
                    autoFocus
                    className="editInput"
                    type="text"
                    value={searchString}
                    placeholder="Search..."
                    onChange={onSearchInputChange}
                />
            </div>

            <div style={{ display: 'inline-block' }}>
                <h1>In-App Events ({sortedIaes.length})</h1>
                <button
                    className="new withHeading"
                    onClick={() => navigate('/newiae/')}
                >
                    Add new
                </button>
            </div>
            <NewRow />

            <div
                style={{ marginBottom: '12px' }}
                onChange={onStatusFilterChange}
            >
                <fieldset>
                    <legend>Status</legend>
                    <input
                        defaultChecked={statusFilter === 'all'}
                        id="status-all"
                        name="statusFilter"
                        type="radio"
                        value="all"
                    />
                    <label htmlFor="status-all">All</label>
                    <input
                        defaultChecked={statusFilter === 'active'}
                        id="status-active"
                        name="statusFilter"
                        type="radio"
                        value="active"
                    />
                    <label htmlFor="status-active">Active</label>
                    <input
                        defaultChecked={statusFilter === 'inactive'}
                        id="status-inactive"
                        name="statusFilter"
                        type="radio"
                        value="inactive"
                    />
                    <label htmlFor="status-inactive">Inactive</label>
                </fieldset>
            </div>
            {/* TODO: add radio filter for event state */}

            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th></th>
                        <th onClick={() => onClickSort('referenceName')}>
                            <div className="sortable">
                                Reference Name
                                <SortIcon
                                    isActive={sortBy === 'referenceName'}
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th
                            onClick={() =>
                                onClickSort(AppEventDateEnum.PUBLISH_START)
                            }
                        >
                            <div
                                className="sortable"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                Publish on
                                <SortIcon
                                    isActive={
                                        sortBy ===
                                        AppEventDateEnum.PUBLISH_START
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th
                            onClick={() =>
                                onClickSort(AppEventDateEnum.EVENT_START)
                            }
                        >
                            <div
                                className="sortable"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                Start
                                <SortIcon
                                    isActive={
                                        sortBy === AppEventDateEnum.EVENT_START
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th
                            onClick={() =>
                                onClickSort(AppEventDateEnum.EVENT_END)
                            }
                        >
                            <div
                                className="sortable"
                                style={{ justifyContent: 'flex-end' }}
                            >
                                End
                                <SortIcon
                                    isActive={
                                        sortBy === AppEventDateEnum.EVENT_END
                                    }
                                    isSortAscend={isSortAscend}
                                />
                            </div>
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>{sortedIaes.map((item) => makeRow(item))}</tbody>
            </table>
        </Section>
    )
}

export default InAppEvents

import React from 'react'

const Alert = ({ heading, text, done, okLabel }) => {
    return (
        <div>
            <h1>{heading || 'Really do this?'}</h1>
            {text && <p>{text}</p>}
            <div style={{ textAlign: 'right', marginTop: 30 }}>
                <button
                    className="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() => done()}
                >
                    {okLabel || 'OK'}
                </button>
            </div>
        </div>
    )
}

export default Alert

import React, { useState } from 'react'

const SignIn = (props) => {
    const [username, onChangeUsername] = useState('')
    const [password, onChangePassword] = useState('')
    return (
        <div
            tabIndex="0"
            onKeyDown={(e) =>
                e.key === 'Enter' &&
                props.done({ username: username, password: password })
            }
        >
            <h1>{props.heading || 'So what?'}</h1>
            <p>Username</p>
            <div style={{ marginBottom: 15 }}>
                <input
                    autoFocus
                    value={username}
                    onChange={(e) => onChangeUsername(e.target.value)}
                    style={{ boxSizing: 'border-box', width: '100%' }}
                />
            </div>

            <p>Password</p>
            <div style={{ marginBottom: 15 }}>
                <input
                    value={password}
                    type="password"
                    onChange={(e) => onChangePassword(e.target.value)}
                    style={{ boxSizing: 'border-box', width: '100%' }}
                />
            </div>

            <div style={{ textAlign: 'right' }}>
                <button className="secondary" onClick={() => props.cancel()}>
                    {props.cancelLabel || 'Cancel'}
                </button>
                <button
                    className="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() =>
                        props.done({ username: username, password: password })
                    }
                >
                    {props.okLabel || 'OK'}
                </button>
            </div>
        </div>
    )
}

export default SignIn

import React from 'react'
import { Tooltip } from 'react-tooltip'
import moment from 'moment'

import accessoryImg from '../assets/accessory.png'
import buildingImg from '../assets/building.png'
import characterImg from '../assets/character.png'
import furnitureImg from '../assets/furniture.png'
import garmentImg from '../assets/garment.png'
import hairImg from '../assets/hair.png'
import outfitImg from '../assets/outfit.png'
import patternImg from '../assets/pattern.png'
import stickerImg from '../assets/sticker.png'
import surfaceImg from '../assets/surface.png'
import templateImg from '../assets/template.png'

const LabelInput = (props) => {
    const handleIcon = () => {
        if (!props.icon) return
        let icon
        switch (props.icon) {
            case 'building':
                icon = buildingImg
                break
            case 'character':
                icon = characterImg
                break
            case 'template':
                icon = templateImg
                break
            case 'furniture':
                icon = furnitureImg
                break
            case 'surface':
                icon = surfaceImg
                break
            case 'garment':
                icon = garmentImg
                break
            case 'pattern':
                icon = patternImg
                break
            case 'sticker':
                icon = stickerImg
                break
            case 'outfit':
                icon = outfitImg
                break
            case 'accessory':
                icon = accessoryImg
                break
            case 'hair':
                icon = hairImg
                break
        }
        if (!icon) return <></>
        return (
            <img
                alt={props.icon}
                style={{ height: 23, marginBottom: -6, marginRight: 15 }}
                src={icon}
            ></img>
        )
    }

    let path = props.object

    if (props.path) {
        let paths = props.path.split('/')
        for (let i = 0; i < paths.length; i++) {
            path = path[paths[i]]
        }
    }

    if (props.path && Number.isInteger(props.i)) path = path[props.i]

    let {
        value,
        checked,
        placeholder,
        size,
        autoFocus,
        label,
        autoComplete,
        className,
        style,
        onChange,
        name,
        disabled,
        tooltip,
        inputStyle,
        labelClassName,
        title,
        pattern,
    } = props
    if (value === undefined)
        value =
            (props.language &&
                path[props.name] &&
                path[props.name][props.language]) ||
            path[props.name]

    if (props.language)
        value = (path[props.name] && path[props.name][props.language]) || ''

    // handle day of week
    let day = null
    if (props.type === 'datetime-local') {
        switch (moment(value).isoWeekday()) {
            case 1:
                day = 'Monday'
                break
            case 2:
                day = 'Tuesday'
                break
            case 3:
                day = 'Wednesday'
                break
            case 4:
                day = 'Thursday'
                break
            case 5:
                day = 'Friday'
                break
            case 6:
                day = 'Saturday'
                break
            case 7:
                day = 'Sunday'
                break
            default:
                day = '?'
        }
    }

    if (!value && value !== 0) value = ''

    const highlightStyle = { fontWeight: 700 }

    return (
        <div
            data-tooltip-content={tooltip || ''}
            data-tooltip-id="input-tooltip"
            data-data-place="right"
            className={className ? 'editInput ' + className : 'editInput'}
            style={style || {}}
        >
            {label && day && (
                <label htmlFor={name}>
                    {label} on a <span style={highlightStyle}>{day}</span>{' '}
                </label>
            )}
            {label && !day && (
                <label htmlFor={name} className={labelClassName ?? ''}>
                    {label}
                    {props.labelHighlight && (
                        <span style={highlightStyle}>
                            {props.labelHighlight}
                        </span>
                    )}
                </label>
            )}

            {handleIcon()}
            <input
                style={inputStyle || {}}
                disabled={disabled}
                title={title}
                pattern={pattern}
                autoComplete={autoComplete || 'off'}
                autoFocus={autoFocus || false}
                className={size}
                placeholder={placeholder}
                checked={checked}
                type={props.type || 'text'}
                value={value}
                name={name}
                id={name}
                onChange={(e) => {
                    onChange(e, props.path, props.i)
                }}
                onKeyPress={(e) => props.onKeyPress && props.onKeyPress(e)}
            />
            <Tooltip id="input-tooltip" delayShow={500} />
        </div>
    )
}

export default LabelInput

import React from 'react'

import wormImg from '../assets/worm.png'

function SpeakBubble(props) {
    let style = props.style || {}

    style.alignItems = 'center'
    if (!style.justifyContent) style.justifyContent = 'center'
    if (!style.display) style.display = 'flex'
    if (!style.marginRight && style.marginLeft !== 0) style.marginRight = 'auto'
    if (!style.marginLeft && style.marginLeft !== 0) style.marginLeft = 'auto'

    if (props.width) style.width = props.width + 'px'

    return (
        <div style={style}>
            <img alt="" src={wormImg}></img>
            <div className="speech-bubble">{props.children}</div>
        </div>
    )
}

export default SpeakBubble

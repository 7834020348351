import React from 'react'

import devices from './devices'

function LanguageSelector(props) {
    const { device, onChange } = props

    function changeAndRemember(e) {
        let device = e.target.value
        localStorage.setItem('device', devices[device])
        onChange(device)
    }

    return (
        <select
            className="blurrable"
            value={device}
            onChange={changeAndRemember}
        >
            {Object.keys(devices).map((item, i) => {
                return (
                    <option key={'device' + i} value={item}>
                        {devices[item].name}
                    </option>
                )
            })}
        </select>
    )
}

export default LanguageSelector

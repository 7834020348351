import React from 'react'
import { Tooltip } from 'react-tooltip'

import errorImg from '../assets/error.png'
import validImg from '../assets/valid.png'
import warningImg from '../assets/warning.png'

function Validator(props) {
    let validationResults = props.validationResults

    let warnings = validationResults.warnings
    let errors = validationResults.errors

    let result = errors.error ? errors : warnings
    let isError = errors.error

    let id = props.id || 'global'

    if (!result) return <div></div>

    let isValid = !result.error
    let style = props.style || {}
    if (!props.showValid && isValid) style.display = 'none'
    else style.display = 'inline-block'
    let asset = validImg
    if (!isValid) asset = isError ? errorImg : warningImg

    return (
        <div style={style} data-tooltip-id={id}>
            <img
                alt="delete"
                style={{ height: props.height || 35, display: 'block' }}
                src={asset}
            ></img>
            <div style={{ textAlign: 'left' }}>
                <Tooltip id={id} place="left">
                    {!isValid &&
                        (result.error.details ? (
                            result.error.details.map((item, i) => (
                                <div key={'notvalid' + id + '_' + i}>
                                    {item.message}
                                </div>
                            ))
                        ) : (
                            <div key={'notvalid' + id}>
                                {result.error.message}
                            </div>
                        ))}
                    {isValid && props.showValid && 'All good!'}
                </Tooltip>
            </div>
        </div>
    )
}

export default Validator

import React from 'react'
import Switch from 'react-switch'

import { Modal } from '../shared'

import deleteImg from '../assets/delete.png'

const TestItem = ({ name, value, onChange, i, deleteItem }) => {
    const update = (value) => onChange(name, value)

    let type = typeof value

    let fixedName = ''
    for (let i = 0; i < name.length; i++) {
        let c = name[i]
        if (i === 0) fixedName += c.toUpperCase()
        else if (c === c.toUpperCase()) fixedName += ` ${c.toLowerCase()}`
        else fixedName += c
    }

    const changeColor = async () => {
        const c = await Modal.colorpicker({ heading: 'Pick a lovely color' })
        if (!c) return
        update(c.hex)
    }

    if (type === 'string' && value.indexOf('#') > -1) type = 'color'

    return (
        <div
            className="ABTestItem"
            style={{
                animation: `.5s linear ${i / 20}s normal forwards 1 fadein`,
            }}
        >
            <label style={{ width: 300 }}>{fixedName}</label>
            <div style={{ width: 260, display: 'flex', alignItems: 'center' }}>
                {type === 'boolean' && (
                    <Switch
                        onChange={update}
                        checked={value}
                        className="react-switch"
                        handleDiameter={28}
                        onColor="#24D86A"
                        offColor="#ccc"
                        height={40}
                        width={70}
                    />
                )}
                {type === 'string' && (
                    <input
                        type="text"
                        onChange={(e) => update(e.target.value)}
                        value={value}
                    />
                )}
                {type === 'color' && (
                    <>
                        <input
                            type="text"
                            onChange={(e) => update(e.target.value)}
                            value={value}
                        />
                        <div
                            style={{
                                width: 35,
                                height: 35,
                                boxShadow: 'rgba(0,0,0,.2) 1px 1px 1px',
                                background: value,
                                display: 'inline-block',
                                borderRadius: 5,
                                marginLeft: 10,
                            }}
                            onClick={changeColor}
                        />
                    </>
                )}
            </div>
            <img
                alt="delete"
                style={{ height: 35, cursor: 'pointer' }}
                onClick={() => deleteItem(name)}
                src={deleteImg}
            ></img>
        </div>
    )
}

export default TestItem

import React, { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'

import styles from './Button.module.scss'

export enum ButtonKind {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    DANGER = 'DANGER',
}
export type ButtonKindUnion = `${ButtonKind}`

const ButtonKindToStyle = {
    [ButtonKind.PRIMARY]: styles.primary,
    [ButtonKind.SECONDARY]: styles.secondary,
    [ButtonKind.DANGER]: styles.danger,
}

type ButtonProps = {
    kind?: ButtonKindUnion
} & ButtonHTMLAttributes<HTMLButtonElement>

export function Button(props: ButtonProps) {
    const { kind, className, type, children, ...buttonAttributes } = props
    return (
        <button
            {...buttonAttributes}
            className={classNames(
                className,
                styles.button,
                kind && ButtonKindToStyle[kind]
            )}
            type={type ?? 'button'}
        >
            {children}
        </button>
    )
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnDragEndResponder } from '@hello-pangea/dnd'
import axios from 'axios'
import _ from 'lodash'

import ShopTabsTable from './Table/ShopTabsTable.tsx'
import { Loading, Modal, NewRow, Section } from '../shared'

import { handleEnvAuth } from '../utils/envaccess'
import {
    fetchDataFromPath,
    postDataForPath,
    putDataForPath,
} from '../utils/envcopy'
import { copyMedias } from '../utils/upload'
import { handleError, moveListItem } from '../utils/utils'

import { LanguageType } from '../types/enums/languagetype.enum.ts'

import { Environment } from '../types/environmentTypes'
import { Media } from '../types/mediaTypes'
import { Tab } from '../types/tabTypes'

type ShopTabsProps = {
    language: LanguageType
}

const ShopTabs = function (props: ShopTabsProps) {
    const [hasLoaded, setHasLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState('Loading...')
    const [isCopyingMedia, setIsCopyingMedia] = useState(false)
    const [copyMediaProgress, setCopyMediaProgress] = useState({
        current: 0,
        max: 0,
    })
    const [tabs, setTabs] = useState<Tab[]>([])
    const navigate = useNavigate()

    const fetchTabs = async () => {
        setIsLoading(true)
        setHasLoaded(true)
        try {
            const response = await axios.get<Tab[]>('/api/v2/shoptabs')
            setTabs(response.data)
            setIsLoading(false)
        } catch (e) {
            handleError('Error receiving shop tabs', e)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!hasLoaded) fetchTabs()
    })

    const onDragEnd: OnDragEndResponder = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        if (result.destination.index === result.source.index) {
            return
        }

        const items = moveListItem(
            tabs,
            result.source.index,
            result.destination.index
        )
        saveTabsOrder(items)
        setTabs(items)
    }

    const saveTabsOrder = async (tabsWithNewOrder: Tab[]) => {
        const ids = tabsWithNewOrder.map((tab) => tab.id)

        try {
            await axios.put('/api/v2/shoptabs', { ids })
            console.log('Tabs reordered')
        } catch (e) {
            handleError('Error reordering tabs', e)
        }
    }

    const redirectToTabDetails = (tabId: Tab['id']) => {
        navigate('/shoptab/' + tabId)
    }

    const deleteTab = async (
        e: React.MouseEvent<HTMLButtonElement>,
        tabId: Tab['id']
    ) => {
        e.stopPropagation()

        const isConfirmed = await Modal.confirm({
            heading: 'Delete tab',
            text: 'Really delete this tab?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        try {
            await axios.delete('/api/v2/shoptabs/' + tabId)
            console.log('Tab deleted')

            const refreshedTabs = tabs.filter((tab) => tab.id !== tabId)
            setTabs(refreshedTabs)
        } catch {
            handleError('Error deleting tab', e)
        }
    }

    const toggleActivateStatus = async (
        e: React.MouseEvent<HTMLButtonElement>,
        tab: Tab
    ) => {
        e.stopPropagation()

        const isConfirmed = await Modal.confirm({
            heading: `${tab.active ? 'Deactivate' : 'Activate'} tab`,
            text: `Really ${
                tab.active ? 'deactivate' : 'activate'
            } tab with name "${tab.name}"?`,
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        try {
            const url = `/api/v2/shoptabs/${tab.id}/toggleactive`
            await axios.put(url, { setActive: !tab.active })

            const refreshedTabs = tabs.map((tabToUpdate) => {
                if (tabToUpdate.id === tab.id) tabToUpdate.active = !tab.active
                return tabToUpdate
            })
            setTabs(refreshedTabs)
        } catch {
            handleError('Error activating / deactivating tab', e)
        }
    }

    const onMediaUploadCompleteCallback = () => {
        setCopyMediaProgress((prevCopyMediaProgress) => ({
            ...prevCopyMediaProgress,
            current: _.clamp(
                prevCopyMediaProgress.current + 1,
                prevCopyMediaProgress.max
            ),
        }))
    }

    const prepareTabContentToCopy = async (tab: Tab, env: Environment) => {
        if (!_.isEmpty(tab.packIds)) {
            const foundPacks = []

            for (const packId of tab.packIds) {
                const fetchedData = await fetchDataFromPath(
                    `${env.basepath}/v2/pack/${packId}`
                )
                const fetchedPack = fetchedData.pack
                if (!_.isEmpty(fetchedPack)) {
                    foundPacks.push(fetchedPack.packId)
                }
            }
            const isConfirmed = await Modal.confirm({
                heading: 'Packs found',
                text: `${foundPacks.length} out of ${tab.packIds.length} packs were found in ${env.name}, do you still want to continue?`,
                okLabel: 'Continue',
            })
            if (!isConfirmed) return false

            tab.packIds = foundPacks
        }

        if (tab.medias.length) {
            const handleMedia = await Modal.select({
                items: [
                    {
                        label: 'Copy media',
                        value: 'yes',
                        disabled: false,
                    },
                    {
                        label: 'Skip media',
                        value: 'no',
                        disabled: false,
                    },
                ],
                heading: 'Also copy media?',
                okLabel: 'Continue',
                hint: `Media will be copied to ${env.name}`,
                inputName: 'media',
            })
            if (!handleMedia) return false

            if (handleMedia === 'yes') {
                setIsCopyingMedia(true)
                const { medias } = tab

                const copyMediaProgress = {
                    current: 1,
                    max: medias.length,
                }
                setCopyMediaProgress(copyMediaProgress)

                const newMedias = await copyMedias(
                    medias as Media[],
                    tab.name.toLowerCase(),
                    env,
                    onMediaUploadCompleteCallback
                )
                tab.mediaIds = newMedias.map((m) => m.mediaId)

                setIsCopyingMedia(false)
            }
        }

        // Set new tab to inactive and at the top of the tabs list
        tab.active = false
        tab.priority = 0

        return tab
    }

    const copyTab = async (
        e: React.MouseEvent<HTMLButtonElement>,
        item: Tab
    ) => {
        e.stopPropagation()

        setLoadingMessage('Copying shop tab...')
        setIsLoading(true)

        const env = await handleEnvAuth()
        if (!env) {
            setLoadingMessage('Loading...')
            setIsLoading(false)
            return
        }

        // Get and compare tabs
        const currentTab = await fetchDataFromPath(
            `/api/v2/shoptabs/${item.id}`
        )
        const refTab = await fetchDataFromPath(
            `${env.basepath}/v2/shoptabs/name/${item.name}`
        )

        if (_.isEmpty(refTab)) {
            // No tab in target environment, show confirm modal
            const isConfirmed = await Modal.confirm({
                heading: 'Copy tab',
                text: `No tab with name '${item.name}' found in ${env.name}, do you want to create new tab?`,
                okLabel: 'Yes do it!',
            })
            if (!isConfirmed) {
                setLoadingMessage('Loading...')
                setIsLoading(false)
                return
            }
        } else {
            // Tab was found, show compare modal
            const isCompareConfirmed = await Modal.compare({
                heading: 'Compare tabs',
                text: 'The following differences were found',
                okLabel: 'Copy',
                object1: refTab,
                object2: currentTab,
            })
            if (!isCompareConfirmed) {
                setLoadingMessage('Loading...')
                setIsLoading(false)
                return
            }
        }

        // Prepare tab content before copy
        setLoadingMessage('Preparing shop tab content...')
        const tab = await prepareTabContentToCopy(currentTab, env)
        if (!tab) {
            setLoadingMessage('Loading...')
            setIsLoading(false)
            return
        }

        // Finally, copy tab to target environment
        setLoadingMessage(`Saving tab to ${env.name}...`)

        const hasCopiedSuccessfully = _.isEmpty(refTab)
            ? await postDataForPath(tab, `${env.basepath}/v2/shoptabs`)
            : await putDataForPath(
                  tab,
                  `${env.basepath}/v2/shoptabs/${refTab.id}`
              )

        setIsLoading(false)
        setLoadingMessage('Loading...')

        const modalHeading = hasCopiedSuccessfully ? 'Copying done!' : 'On no!'
        const modalText = hasCopiedSuccessfully
            ? `Woho, the tab with name '${tab.name}' was successfully stored in ${env.name}!`
            : `Something went wrong when trying to copy tab with name '${tab.name}' to ${env.name}!`

        await Modal.alert({
            heading: modalHeading,
            text: modalText,
        })
    }

    return (
        <Section>
            <div style={{ display: 'inline-block' }}>
                <h1>Shop tabs</h1>
                <button
                    className="new withHeading"
                    onClick={() => navigate('/newshoptab/')}
                >
                    Add new
                </button>
            </div>
            <NewRow />
            {isLoading && (
                <Loading
                    text={
                        isCopyingMedia
                            ? `Uploading media file ${copyMediaProgress.current} of ${copyMediaProgress.max}...`
                            : loadingMessage
                    }
                />
            )}
            {!isLoading && tabs && (
                <Section>
                    <ShopTabsTable
                        tabs={tabs}
                        onTabClick={redirectToTabDetails}
                        onDragEnd={onDragEnd}
                        onCopyTabClick={copyTab}
                        onDeleteTabClick={deleteTab}
                        onToggleActiveClick={toggleActivateStatus}
                        {...props}
                    />
                </Section>
            )}
        </Section>
    )
}

export default ShopTabs

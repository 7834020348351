import React from 'react'

import StatusLabel, { ChangeStatuses } from './StatusLabel'

import { toHTMLDate, toJSONDate } from '../../utils/dates'

import styles from './DatePicker.module.scss'

import resetIcon from '../../assets/reset.svg'

type DateProps = {
    label: string
    date: string
    isDisabled?: boolean
    isInvalid?: boolean
    isUpdated?: boolean
    onChange?: (date?: string) => void
}

function Date(props: DateProps) {
    const { label, date, isDisabled, isInvalid, isUpdated, onChange } = props
    return (
        <>
            <input
                disabled={isDisabled}
                aria-label={label}
                type="datetime-local"
                value={toHTMLDate(date)}
                onChange={(e) => onChange?.(toJSONDate(e.target.value))}
            />
            {!isDisabled && (
                <img
                    alt="Reset date"
                    className={styles.reset}
                    src={resetIcon}
                    onClick={() => onChange?.(undefined)}
                />
            )}
            {isInvalid && <StatusLabel status={ChangeStatuses.INVALID} />}
            {!isInvalid && isUpdated && (
                <StatusLabel status={ChangeStatuses.UPDATED} />
            )}
        </>
    )
}

export default Date

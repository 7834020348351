import React, { ChangeEventHandler, Fragment } from 'react'
import _ from 'lodash'

import Selector from '../../shared/Selector'
import { Checkbox } from '../../shared'

import { mediaTypeToDisplayText } from '../../settings'

import { MediaType } from '../../types/enums/mediatype.enum'

import styles from './Header.module.scss'

type HeaderProps = {
    sortBy?: string
    order?: string
    filterByType?: MediaType[]
    filterByDateFrom?: string
    filterByDateTo?: string
    onSortChange: ChangeEventHandler<HTMLSelectElement>
    onTypeFilterChange: (toggledType: MediaType) => void
    onDateFilterChange: ChangeEventHandler<HTMLInputElement>
}

const entityToMediaTypes = {
    pack: [
        MediaType.SCREENSHOT,
        MediaType.VIDEO,
        MediaType.THUMBNAIL,
        MediaType.WIDE_THUMBNAIL,
        MediaType.TAB_THUMBNAIL,
        MediaType.IAP_IMAGE,
    ],
    map: [MediaType.MAP_LOCATION, MediaType.MAP_BACKGROUND],
    tab: [MediaType.TAB_BACKGROUND, MediaType.TAB_ICON],
}

const sortPropertyToLabel = {
    created: 'Date',
    url: 'Name',
    type: 'Type',
}

function Header(props: HeaderProps) {
    const {
        sortBy,
        order,
        filterByType,
        filterByDateFrom,
        filterByDateTo,
        onSortChange,
        onTypeFilterChange,
        onDateFilterChange,
    } = props
    return (
        <div className={styles.header}>
            <div className={styles.filters}>
                <div className={styles.typeFilters}>
                    {Object.entries(entityToMediaTypes).map(
                        ([entity, mediaTypes]) => (
                            <fieldset key={entity}>
                                <legend>{_.capitalize(entity)}</legend>
                                {mediaTypes.map((type) => (
                                    <Checkbox
                                        key={type}
                                        label={mediaTypeToDisplayText[type]}
                                        checked={filterByType?.includes(type)}
                                        onToggle={() =>
                                            onTypeFilterChange(type)
                                        }
                                    />
                                ))}
                            </fieldset>
                        )
                    )}
                </div>
                <div className={styles.dateFilters}>
                    <div className={styles.dateFilter}>
                        <label
                            className={styles.label}
                            htmlFor="medias-filter-by-date-from"
                        >
                            From
                        </label>
                        <input
                            type="date"
                            id="medias-filter-by-date-from"
                            name="filterByDateFrom"
                            value={filterByDateFrom || ''}
                            onChange={onDateFilterChange}
                        />
                    </div>
                    <div className={styles.dateFilter}>
                        <label
                            className={styles.label}
                            htmlFor="medias-filter-by-date-to"
                        >
                            To
                        </label>
                        <input
                            type="date"
                            id="medias-filter-by-date-to"
                            name="filterByDateTo"
                            value={filterByDateTo || ''}
                            onChange={onDateFilterChange}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.sort}>
                <label htmlFor="medias-sort" className={styles.label}>
                    Sort
                </label>
                <Selector
                    id="medias-sort"
                    name="medias-sort"
                    value={sortBy && order ? `${sortBy}:${order}` : ''}
                    onChange={onSortChange}
                >
                    <option value="">Default</option>
                    {Object.entries(sortPropertyToLabel).map(
                        ([property, label]) => (
                            <Fragment key={property}>
                                <option value={`${property}:asc`}>
                                    {label}: Ascend
                                </option>
                                <option value={`${property}:desc`}>
                                    {label}: Descend
                                </option>
                            </Fragment>
                        )
                    )}
                </Selector>
            </div>
        </div>
    )
}

export default Header

import { compareDates } from '../utils/dates'
import { filterActive } from '../utils/utils'

import { LanguageType } from '../types/enums/languagetype.enum.ts'
import { PackType } from '../types/enums/packtype.enum'

import { Pack } from '../types/packTypes'

export function removeStandalonePacks(packs: Pack[]) {
    return packs.filter((pack) => pack.type !== PackType.STANDALONE)
}

export function filterPacksBySearchString(
    packs: Pack[],
    searchString: string,
    language: LanguageType
) {
    if (!searchString) {
        return packs
    }

    const loweredSearchString = searchString.toLowerCase()
    return packs.filter(
        (pack) =>
            pack.packId?.toLowerCase()?.includes(loweredSearchString) ||
            pack.name?.toLowerCase()?.includes(loweredSearchString) ||
            pack.displayName?.[language]
                ?.toLowerCase()
                ?.includes(loweredSearchString)
    )
}

export function filterPacksByStatus(packs: Pack[], filterByStatus: string) {
    if (filterByStatus === 'all') {
        return packs
    }
    return filterActive(
        packs,
        filterByStatus === 'active',
        'activeFromDate',
        'activeToDate'
    )
}

export function filterPacksByType(packs: Pack[], filterType: string) {
    if (filterType === 'all') {
        return packs
    }

    return packs.filter((pack) => pack.type === filterType)
}

export type SortByType = 'name' | 'activeFromDate' | 'activeToDate'

export const comparators: Record<SortByType, (a: Pack, b: Pack) => number> = {
    name: (a: Pack, b: Pack) =>
        a.name.localeCompare(b.name, undefined, {
            sensitivity: 'base',
        }),
    activeFromDate: (a: Pack, b: Pack) =>
        compareDates(a.activeFromDate, b.activeFromDate),
    activeToDate: (a: Pack, b: Pack) =>
        compareDates(a.activeToDate, b.activeToDate),
}

export function sortPacks(
    packs: Pack[],
    sortBy: SortByType | undefined,
    isSortAscend: boolean
) {
    if (!sortBy) {
        return packs
    }

    const comparator = comparators[sortBy]

    const sorted = packs.slice().sort(comparator)

    if (isSortAscend) {
        return sorted
    }
    return sorted.toReversed()
}

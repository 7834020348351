import React, { useState } from 'react'
import { DraggableCore } from 'react-draggable'
import { Tooltip } from 'react-tooltip'
import classNames from 'classnames'

import { onSelectPhoto } from '../utils/upload'
import { getPackDisplayName } from '../utils/utils'

import { PackType } from '../types/enums/packtype.enum'

import styles from './Playset.module.scss'

import checkImg from '../assets/check.png'
import playsetPlaceholderImg from '../assets/playsetPlaceholder.png'
import plusImg from '../assets/plus.png'

function Playset(props) {
    const [isOwned, toggleIsOwned] = useState(false)

    let { x, y, img, imgOwned, packId, pricetag, page } = props.data
    const {
        i,
        isActive,
        setActive,
        scale,
        drawScale,
        width,
        height,
        opacity,
        rotate,
        playsetData,
        onPlaysetImageSelected,
        onPlaysetImageUploaded,
        onEditPlaysetOrder,
        setMapPage,
    } = props

    function changePage(n) {
        let data = props.data
        data.page += n
        setMapPage(data.page)
        props.updatePlayset(data, i)
    }

    function onDrag(e, { deltaX, deltaY }) {
        let data = props.data
        data.x += deltaX / drawScale / width
        data.y += deltaY / drawScale / height
        props.updatePlayset(data, i)
    }

    let controlsStyle = {}

    let wrapperStyle = {
        transform: 'scale(' + scale + ')',
        left: x * width,
        top: y * height,
    }

    let url = playsetPlaceholderImg
    controlsStyle.visibility = 'hidden' // always show upload panel if nothing was uploaded earlier

    if (isActive) controlsStyle.visibility = 'visible'

    if (isOwned && imgOwned && imgOwned.url)
        url = imgOwned.url.replace('{0}', '@3x')
    if (!isOwned && img && img.url) url = img.url.replace('{0}', '@3x')

    controlsStyle.transform = 'scale(' + 0.7 / drawScale + ')'

    if (pricetag.y > 0.075) {
        controlsStyle.display = 'absolute'
        controlsStyle.top = -90 / drawScale
    }

    let name = getPackDisplayName(playsetData)

    const playsetId = 'playset' + i

    return (
        <DraggableCore onDrag={onDrag}>
            <div
                style={wrapperStyle}
                className={classNames(
                    styles.playset,
                    isActive ? styles.active : ''
                )}
            >
                <img
                    src={url}
                    draggable="false"
                    onClick={(e) => setActive(e, i, 'playset')}
                    style={{
                        opacity: isActive ? opacity : 1,
                        display: 'block',
                        outlineWidth: 5 / scale,
                    }}
                    alt=""
                />
                <div className={styles.controls} style={controlsStyle}>
                    <div className={styles.panel}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={'/pack/' + packId}
                        >
                            {name}
                        </a>
                        <input
                            type="file"
                            name=""
                            id={'playsetselect_' + i}
                            accept="Image/png"
                            onChange={(e) =>
                                onSelectPhoto(
                                    e,
                                    'maplocation',
                                    onPlaysetImageSelected,
                                    onPlaysetImageUploaded,
                                    {
                                        packId,
                                        fileName: 'maplocation_' + packId,
                                        isOwned,
                                    }
                                )
                            }
                        />
                        <label
                            className={classNames('upload', styles.upload)}
                            htmlFor={'playsetselect_' + i}
                        >
                            Upload
                        </label>
                        <button onClick={() => rotate(i)}>Rotate</button>
                        <button
                            className={styles.arrowButton}
                            onClick={(e) => onEditPlaysetOrder(e, 1)}
                        >
                            ⬆
                        </button>
                        <button
                            className={styles.arrowButton}
                            onClick={(e) => onEditPlaysetOrder(e, -1)}
                        >
                            ⬇
                        </button>
                        <div className={styles.toggleContainer}>
                            <button
                                data-tooltip-content="Upload asset for not owned"
                                data-tooltip-id={playsetId}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    toggleIsOwned(false)
                                }}
                                className={classNames(
                                    styles.toggle,
                                    styles.leftToggle,
                                    isOwned ? styles.isOwned : ''
                                )}
                            >
                                <img alt="possible to buy" src={plusImg} />
                            </button>
                            <button
                                data-tooltip-content="Upload asset for owned"
                                data-tooltip-id={playsetId + 'b'}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    toggleIsOwned(true)
                                }}
                                className={classNames(
                                    styles.toggle,
                                    styles.rightToggle,
                                    isOwned ? styles.isOwned : ''
                                )}
                            >
                                <img alt="owned" src={checkImg} />
                            </button>
                        </div>
                        <Tooltip delayShow={500} id={playsetId} />
                        <Tooltip delayShow={500} id={playsetId + 'b'} />
                        {(playsetData.type === PackType.HOME_DESIGNER ||
                            playsetData.type === PackType.OUTFIT_MAKER ||
                            playsetData.type ===
                                PackType.CHARACTER_CREATOR) && (
                            <div
                                style={{
                                    marginTop: 4,
                                }}
                            >
                                <button
                                    className="small"
                                    disabled={page === 0}
                                    onClick={() => changePage(-1)}
                                >
                                    {'<'} page
                                </button>
                                <button
                                    className="small"
                                    onClick={() => changePage(1)}
                                >
                                    page {'>'}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </DraggableCore>
    )
}

export default Playset

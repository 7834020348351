import React, { useEffect, useRef } from 'react'
import { AxiosResponse } from 'axios'
import _ from 'lodash'

import { PackMediaSection } from './PackMediaSection.tsx'
import { PackScreenshotsSection } from './PackScreenshotsSection.tsx'
import { PackVideosSection } from './PackVideosSection.tsx'

import { ImageFileUploading, VideoFileUploading } from '../../utils/upload'

import { MediaType } from '../../types/enums/mediatype.enum.ts'
import { PackType } from '../../types/enums/packtype.enum.ts'

import { Media } from '../../types/mediaTypes'
import { Pack } from '../../types/packTypes'

type MediaOrImageFileUploading = Media | (ImageFileUploading & { id?: number })
type MediaOrVideoFileUploading = Media | (VideoFileUploading & { id?: number })

type MediaGroups =
    | 'screenshots'
    | 'videos'
    | 'thumbnails'
    | 'widethumbnails'
    | 'tabthumbnails'
    | 'iapimages'

type PackScreenshotsProps = {
    pack: Pack
    media: {
        screenshots: MediaOrImageFileUploading[]
        videos: MediaOrVideoFileUploading[]
        thumbnails: MediaOrImageFileUploading[]
        widethumbnails: MediaOrImageFileUploading[]
        tabthumbnails: MediaOrImageFileUploading[]
        iapimages: MediaOrImageFileUploading[]
    }
    childPacksData: Pack[]
    onMediaChanged: (
        type: MediaType,
        items: (MediaOrImageFileUploading | MediaOrVideoFileUploading)[]
    ) => void
    toggleScreenshot: (mediaId: number) => void
}

export const PackScreenshotsWrapper = (props: PackScreenshotsProps) => {
    const { pack, media, childPacksData, toggleScreenshot, onMediaChanged } =
        props
    const mediaRef = useRef(media)

    const {
        screenshots,
        videos,
        thumbnails,
        widethumbnails,
        tabthumbnails,
        iapimages,
    } = media

    useEffect(() => {
        mediaRef.current = media
    }, [media])

    const getMediasByType = (
        type: MediaType
    ): (MediaOrImageFileUploading | MediaOrVideoFileUploading)[] => {
        // Fixme Maybe should have mapping instead of adding 's' and assuming that's correct
        const pluralType = `${type}s` as MediaGroups

        return mediaRef.current[pluralType]
    }

    const onPhotosSelected = (type: MediaType, files: ImageFileUploading[]) => {
        const medias = getMediasByType(type)

        onMediaChanged(type, _.concat(medias, files))
    }

    const onPhotosWasUploaded = (
        type: MediaType,
        file: File,
        res: AxiosResponse<{ uploaded: { id: number } }>
    ) => {
        const medias = getMediasByType(type).map((m) => {
            if ('file' in m && m.file.name === file.name) {
                return _.assign(m, {
                    uploading: false,
                    id: res.data.uploaded.id,
                })
            }

            return m
        })

        onMediaChanged(type, medias)
    }

    const onVideoSelected = (file: VideoFileUploading) => {
        onMediaChanged(MediaType.VIDEO, _.concat(mediaRef.current.videos, file))
    }

    const onVideoWasUploaded = (
        file: File,
        res: AxiosResponse<{ uploaded: { id: number }[] }>
    ) => {
        const videos = getMediasByType(MediaType.VIDEO).map((m) => {
            if ('file' in m && m.file.name === file.name) {
                return _.assign(m, {
                    uploading: false,
                    id: res.data.uploaded[0].id,
                })
            }

            return m
        })

        onMediaChanged(MediaType.VIDEO, videos)
    }

    const onMediaDelete = (index: number, type: MediaType) => {
        const medias: (
            | MediaOrImageFileUploading
            | MediaOrVideoFileUploading
        )[] = getMediasByType(type)

        onMediaChanged(
            type,
            medias.filter((_item, i) => i !== index)
        )
    }

    const onMediaDragEnd = (result: {
        source: { index: number; droppableId: MediaType }
        destination: { index: number }
    }) => {
        if (!result.destination) return

        // reorder results
        const mediaList = Array.from(getMediasByType(result.source.droppableId))

        const [removed] = mediaList.splice(result.source.index, 1)
        mediaList.splice(result.destination.index, 0, removed)

        onMediaChanged(result.source.droppableId, mediaList)
    }

    return (
        <>
            <PackScreenshotsSection
                pack={pack}
                childPacksData={childPacksData}
                items={screenshots}
                onMediaDelete={onMediaDelete}
                onMediasSelected={onPhotosSelected}
                onMediasWasUploaded={onPhotosWasUploaded}
                onMediaDragEnd={onMediaDragEnd}
                toggleScreenshot={toggleScreenshot}
            />
            <PackVideosSection
                pack={pack}
                items={videos}
                onVideoDelete={onMediaDelete}
                onVideoSelected={onVideoSelected}
                onVideoWasUploaded={onVideoWasUploaded}
                onVideoDragEnd={onMediaDragEnd}
            />
            <PackMediaSection
                type={MediaType.THUMBNAIL}
                header={'Thumbnail square (612×612 |  526x700 | 526x794)'}
                pack={pack}
                items={thumbnails}
                onMediaDelete={onMediaDelete}
                onMediasSelected={onPhotosSelected}
                onMediasWasUploaded={onPhotosWasUploaded}
                onMediaDragEnd={onMediaDragEnd}
            />
            {pack.type === PackType.MULTIPACK ? (
                <PackMediaSection
                    type={MediaType.WIDE_THUMBNAIL}
                    header={
                        'Thumbnail for bundle ( 930×523 | 994x666 | 912x620 | 828x574 )'
                    }
                    pack={pack}
                    items={widethumbnails}
                    onMediaDelete={onMediaDelete}
                    onMediasSelected={onPhotosSelected}
                    onMediasWasUploaded={onPhotosWasUploaded}
                    onMediaDragEnd={onMediaDragEnd}
                />
            ) : null}
            <PackMediaSection
                type={MediaType.TAB_THUMBNAIL}
                header={'Tab Thumbnail (564 x 564)'}
                pack={pack}
                items={tabthumbnails}
                onMediaDelete={onMediaDelete}
                onMediasSelected={onPhotosSelected}
                onMediasWasUploaded={onPhotosWasUploaded}
                onMediaDragEnd={onMediaDragEnd}
            />
            <PackMediaSection
                type={MediaType.IAP_IMAGE}
                header={'In-App Purchase Image (1024 x 1024)'}
                pack={pack}
                items={iapimages}
                onMediaDelete={onMediaDelete}
                onMediasSelected={onPhotosSelected}
                onMediasWasUploaded={onPhotosWasUploaded}
                onMediaDragEnd={onMediaDragEnd}
            />
        </>
    )
}

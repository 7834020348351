import React, { useState } from 'react'
import _ from 'lodash'

import { getPackDisplayName } from '../utils/utils'

import { Checkbox, PackLink, SpeechBubble } from './'

function ManagePlaysets(props) {
    const [searchString, onChange] = useState('')
    const {
        items,
        playsets,
        togglePlayset,
        onCancel,
        style,
        title,
        packData,
        hint,
        disableChecked,
        toggleConfirmCallback,
        preselectedItems,
    } = props

    // Show packs in an alphabetic order
    playsets.sort((a, b) => {
        const firstName = getPackDisplayName(a)
        const secondName = getPackDisplayName(b)
        return firstName > secondName ? 1 : secondName > firstName ? -1 : 0
    })

    let speechContent
    if (packData)
        speechContent = (
            <span>
                You can only select packs that are included in{' '}
                <PackLink data={packData} />.
            </span>
        )
    else {
        speechContent = (
            <span>
                Select playsets that are located in this standalone district.
            </span>
        )
    }

    async function onToggle(p, isEnabled) {
        if (!isEnabled) return
        if (!toggleConfirmCallback) return togglePlayset(p)
        let res = await toggleConfirmCallback()
        if (res) togglePlayset(p)
    }

    return (
        <div className="packs part" style={style || {}}>
            <h2>{title || 'Manage packs'}</h2>
            <div>
                <input
                    className="editInput"
                    value={searchString}
                    onChange={(e) => onChange(e.target.value)}
                    type="input"
                    placeholder="Search"
                ></input>
            </div>
            <div style={{ textAlign: 'left' }}>
                {playsets.map((p, i) => {
                    let isChecked = false
                    if (typeof items[0] === 'string')
                        isChecked = items.indexOf(p.packId) > -1
                    else
                        isChecked =
                            _.find(items, { packId: p.packId }) !== undefined
                    let name = getPackDisplayName(p)
                    let isSearched = true
                    let isSearching = searchString.length > 0
                    if (isSearching)
                        isSearched =
                            name
                                .toLowerCase()
                                .indexOf(searchString.toLowerCase()) !== -1

                    let isEnabled = true
                    if (
                        packData &&
                        packData.childPacks &&
                        packData.childPacks.length > 0
                    ) {
                        isEnabled = packData.childPacks.indexOf(p.packId) > -1
                    }

                    if (disableChecked && isChecked) isEnabled = false

                    const isPreselected =
                        preselectedItems && preselectedItems.has(p.packId)
                    if (isPreselected) isEnabled = false

                    return (
                        <div
                            className={
                                isSearched ? 'pack row highlighted' : 'pack row'
                            }
                            key={'pack' + i}
                        >
                            <Checkbox
                                disabled={!isEnabled}
                                label={`${name} (${p.worth})`}
                                highlight={
                                    searchString.length > 0 && isSearched
                                }
                                isSearching={isSearching}
                                checked={isChecked || isPreselected}
                                pack={p}
                                onToggle={() => onToggle(p, isEnabled)}
                            />
                        </div>
                    )
                })}
            </div>
            {hint && (
                <SpeechBubble width="500" style={{ margin: 30 }}>
                    {speechContent}
                </SpeechBubble>
            )}

            {onCancel && (
                <button style={{ marginTop: 30 }} onClick={() => onCancel()}>
                    Done
                </button>
            )}
        </div>
    )
}

export default ManagePlaysets

import React, { ChangeEventHandler, useEffect, useState } from 'react'
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'
import _ from 'lodash'

import ModalV2 from '../shared/ModalV2'
import Pagination from '../shared/pagination/Pagination'
import Header from './header/Header'
import Media from './Media'
import { Loading, MediaImage, Section } from '../shared'

import { Order, SortByOption, useMedias } from '../hooks/medias'

import { handleError } from '../utils/utils'
import { getFileNameFromUrl } from './utils'

import { MediaType as MediaTypeEnum } from '../types/enums/mediatype.enum'

import { Media as MediaType } from '../types/mediaTypes'

import styles from './Medias.module.scss'

export const PAGE_SIZE = 30

function Medias() {
    const { mediasResponse, fetch: fetchMedias, isLoading, error } = useMedias()
    const medias = mediasResponse?.medias || []
    const totalPageCount = mediasResponse?.pagination.totalPageCount || 0

    const [searchParams, setSearchParams] = useSearchParams()
    const page = Number(searchParams.get('page')) || 1
    const sortBy = searchParams.get('sortBy') || undefined
    const order = searchParams.get('order') || undefined
    const filterByType = searchParams.getAll('filterByType[]')
    const filterByDateFrom = searchParams.get('filterByDateFrom') || undefined
    const filterByDateTo = searchParams.get('filterByDateTo') || undefined
    const navigate = useNavigate()

    const [selectedMediaId, setSelectedMediaId] = useState<
        MediaType['id'] | undefined
    >()
    const selectedMedia = _.find(medias, { id: selectedMediaId })

    useEffect(() => {
        fetchMedias({
            page,
            limit: PAGE_SIZE,
            sortBy: sortBy as SortByOption,
            order: order as Order,
            filterByType: filterByType as MediaTypeEnum[],
            filterByDateFrom,
            filterByDateTo,
        })
    }, [
        page,
        sortBy,
        order,
        filterByType.toString(),
        filterByDateFrom,
        filterByDateTo,
    ])

    useEffect(() => {
        // If the current page is greater than the total page count, redirect to the last page. This happens when user adds filters and the total page count decreases.
        if (totalPageCount && page > totalPageCount) {
            const redirectSearchParams = createSearchParams(searchParams)
            redirectSearchParams.set('page', totalPageCount.toString())
            navigate({ search: redirectSearchParams.toString() })
        }
    }, [page, totalPageCount])

    const onTypeFilterChange = (toggledType: MediaTypeEnum) => {
        setSearchParams((searchParams) => {
            searchParams.has('filterByType[]', toggledType)
                ? searchParams.delete('filterByType[]', toggledType)
                : searchParams.append('filterByType[]', toggledType)
            return searchParams
        })
    }

    const onDateFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.currentTarget
        setSearchParams((searchParams) => {
            value ? searchParams.set(name, value) : searchParams.delete(name)
            return searchParams
        })
    }

    const onSortChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        const [sortBy, order] = e.currentTarget.value.split(':')
        setSearchParams((searchParams) => {
            sortBy
                ? searchParams.set('sortBy', sortBy)
                : searchParams.delete('sortBy')
            order
                ? searchParams.set('order', order)
                : searchParams.delete('order')
            return searchParams
        })
    }

    const onMediaClick = (mediaId: MediaType['id']) => {
        setSelectedMediaId(mediaId)
    }

    const onMediaClose = () => {
        setSelectedMediaId(undefined)
    }

    if (error) {
        handleError('Failed to list medias', error)
    }

    return (
        <Section>
            {selectedMedia && (
                <ModalV2>
                    <Media media={selectedMedia} onClose={onMediaClose} />
                </ModalV2>
            )}
            <Header
                sortBy={sortBy}
                order={order}
                filterByType={filterByType as MediaTypeEnum[]}
                filterByDateFrom={filterByDateFrom}
                filterByDateTo={filterByDateTo}
                onSortChange={onSortChange}
                onTypeFilterChange={onTypeFilterChange}
                onDateFilterChange={onDateFilterChange}
            />
            {isLoading && <Loading />}
            {!isLoading && (
                <div className={styles.grid}>
                    {medias.map((media) => (
                        <div key={media.id} className={styles.gridCell}>
                            <div
                                className={styles.imageWrapper}
                                onClick={() => onMediaClick(media.id)}
                            >
                                <MediaImage media={media} />
                            </div>
                            <div className={styles.metadata}>
                                <div className={styles.fileName}>
                                    {getFileNameFromUrl(media.url)}
                                </div>
                                <div className={styles.timestamp}>
                                    {media.created}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.pagination}>
                <Pagination
                    currentPage={page}
                    totalPageCount={totalPageCount}
                />
            </div>
        </Section>
    )
}

export default Medias

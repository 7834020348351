import React from 'react'

import { LabelInput, PackLink, SpeechBubble } from '../shared'

import { languages } from '../utils/utils'

function TitleTranslator(props) {
    const {
        district,
        packData,
        connectToDistrict,
        disconnectDistrict,
        onNameChange,
        onStringIdChange,
        onLoadTranslations,
        isGoogleSheetLoading,
        googleSheetErrors,
        hasGoogleSheetLoadedSuccessfully,
        language,
        parent,
    } = props
    const packId = district.packId

    let speechBubbleMessage = ''
    if (hasGoogleSheetLoadedSuccessfully) {
        speechBubbleMessage = "I've updated the translations for you!"
    }
    if (isGoogleSheetLoading) {
        speechBubbleMessage = 'Loading from Google...'
    }
    if (googleSheetErrors.length) {
        speechBubbleMessage = (
            <ul style={{ margin: 0 }}>
                {googleSheetErrors.map((error, i) => (
                    <li key={i}>{error}</li>
                ))}
            </ul>
        )
    }

    return (
        <div
            style={{
                width: '100%',
                margin: 'auto',
                marginBottom: 55,
                textAlign: 'center',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '70%',
                        columnGap: '5%',
                    }}
                >
                    <LabelInput
                        disabled={Boolean(packId)}
                        object={packData || district}
                        style={{ flex: 'auto' }}
                        inputStyle={{ width: '100%' }}
                        name="stringId"
                        label="String ID"
                        onChange={onStringIdChange}
                    />
                    <LabelInput
                        disabled={packId ? true : false}
                        object={packData || district}
                        style={{ flex: 'auto' }}
                        inputStyle={{ width: '100%' }}
                        placeholder="No name yet"
                        name="displayName"
                        language={language}
                        label={'name in '}
                        labelHighlight={languages[language]}
                        onChange={onNameChange}
                    />
                </div>
            </div>
            {speechBubbleMessage && (
                <SpeechBubble style={{ marginBottom: 10 }}>
                    {speechBubbleMessage}
                </SpeechBubble>
            )}
            <button style={{ marginBottom: 10 }} onClick={onLoadTranslations}>
                Load translations for all districts
            </button>
            {packId ? (
                <div>
                    Connected to <PackLink data={packData} /> (
                    <a
                        onClick={disconnectDistrict}
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        disconnect
                    </a>
                    )
                    <button
                        className="small primary"
                        onClick={() =>
                            parent.setState({ view: 'managePlaysets' })
                        }
                        style={{ marginLeft: 10 }}
                    >
                        Add/remove playsets
                    </button>
                </div>
            ) : (
                <div>
                    Not connected.{' '}
                    <button
                        onClick={connectToDistrict}
                        className="link positive"
                    >
                        Connect to a district
                    </button>
                    <button
                        className="small primary"
                        onClick={() =>
                            parent.setState({ view: 'managePlaysets' })
                        }
                        style={{ marginLeft: 10 }}
                    >
                        Add/remove playsets
                    </button>
                </div>
            )}
        </div>
    )
}

export default TitleTranslator

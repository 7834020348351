import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import {
    Button,
    ButtonKind,
    Footer,
    Status,
    StatusType,
} from '../../shared/ModalV2'
import Selector from '../../shared/Selector'
import PreviewTable from './PreviewTable'
import { Loading } from '../../shared'

import { useAuthenticate, useEnvironments } from '../../hooks/environments'
import {
    useCreatePack,
    usePack,
    usePacks,
    useUpdatePack,
} from '../../hooks/packs'

import { copyMedias } from '../../utils/upload'
import { hasPackChanged } from './utils'

import { Environment } from '../../types/environmentTypes'
import { Pack, PackUpdate } from '../../types/packTypes'

import validate from '../validate'
import styles from './CopyPack.module.scss'

type CopyPackProps = {
    packId: string
    onClose: () => void
}

function CopyPack(props: CopyPackProps) {
    const { packId, onClose } = props
    const [targetEnvName, setTargetEnvName] = useState<string>('')
    const [isMediasIncluded, setIsMediasIncluded] = useState<boolean>(true)
    const [isMediasCopying, setIsMediasCopying] = useState<boolean>(false)
    const [mediasCopyError, setMediasCopyError] = useState<string | undefined>()
    const [activeFrom, setActiveFrom] = useState<string>()
    const [activeTo, setActiveTo] = useState<string>()

    const {
        environments = [],
        isLoading: isEnvsLoading,
        error: envsError,
    } = useEnvironments()
    const {
        isAuthenticated,
        isLoading: isAuthLoading,
        error: authError,
    } = useAuthenticate(targetEnvName)
    const currentEnv = environments.find((env) => env.active)
    const otherEnvs = environments.filter((env) => !env.active)
    const targetEnv = environments.find((env) => env.name === targetEnvName)
    const targetEnvBasePath = targetEnv?.basepath

    const {
        pack,
        fetch: fetchPack,
        isLoading: isPackLoading,
        error: packError,
    } = usePack(packId)
    const {
        packs: packsInTargetEnv,
        fetch: fetchPacks,
        isLoading: isPacksInTargetEnvLoading,
        error: packsInTargetEnvError,
    } = usePacks({ basepath: targetEnvBasePath })
    const packInTargetEnv = packsInTargetEnv?.find(
        (pack) => pack.packId === packId
    )

    const {
        createPack,
        pack: createdPack,
        isLoading: isCreatePackLoading,
        error: createPackError,
    } = useCreatePack({ basepath: targetEnvBasePath })
    const {
        updatePack,
        pack: updatedPack,
        isLoading: isUpdatePackLoading,
        error: updatePackError,
    } = useUpdatePack({ basepath: targetEnvBasePath })

    useEffect(() => {
        fetchPack()
    }, [])

    useEffect(() => {
        if (targetEnvName && isAuthenticated) {
            fetchPacks()
        }
    }, [targetEnvName, isAuthenticated])

    async function copyPack(
        pack: Pack,
        targetEnv: Environment,
        isPackInTargetEnv: boolean
    ) {
        const newPack: PackUpdate = _.assign(_.clone(pack), { medias: [] })

        if (isMediasIncluded) {
            setIsMediasCopying(true)
            setMediasCopyError(undefined)
            const newMedias = await copyMedias(
                pack.medias,
                pack.packId,
                targetEnv,
                (_res, success) =>
                    !success && setMediasCopyError('Failed to copy media')
            )
            setIsMediasCopying(false)
            const newMediaIds = newMedias.map((media) => media.mediaId)
            newPack.medias = newMediaIds
        }

        isPackInTargetEnv ? updatePack(newPack) : createPack(newPack)
    }

    if (
        isPackLoading ||
        isEnvsLoading ||
        isAuthLoading ||
        isPacksInTargetEnvLoading ||
        isMediasCopying ||
        isCreatePackLoading ||
        isUpdatePackLoading
    ) {
        return (
            <>
                <Loading />
                <Footer>
                    <Button kind={ButtonKind.SECONDARY} onClick={onClose}>
                        Close
                    </Button>
                </Footer>
            </>
        )
    }

    if (
        packError ??
        envsError ??
        (targetEnvName && authError) ??
        packsInTargetEnvError ??
        mediasCopyError ??
        createPackError ??
        updatePackError
    ) {
        return (
            <>
                <Status
                    type={StatusType.Error}
                    header="Oops, something went wrong"
                    body="Please try again or contact system administrator."
                />
                <Footer>
                    <Button kind={ButtonKind.SECONDARY} onClick={onClose}>
                        Close
                    </Button>
                </Footer>
            </>
        )
    }

    if (createdPack || updatedPack) {
        const host = targetEnv?.basepath.replace('/api', '')
        return (
            <>
                <Status
                    type={StatusType.Info}
                    header={`Pack successfully copied to ${targetEnvName}`}
                    body={
                        <div className={styles.successBody}>
                            <div>
                                You can now close the pop up. Please verify the
                                pack in the selected environment.
                            </div>
                            <a
                                href={`${host}/pack/${packId}`}
                                target="_blank"
                                rel="noreferrer"
                                className={styles.buttonLink}
                            >
                                View copied pack
                            </a>
                        </div>
                    }
                />
                <Footer>
                    <Button kind={ButtonKind.SECONDARY} onClick={onClose}>
                        Close
                    </Button>
                </Footer>
            </>
        )
    }

    if (pack) {
        const validationErrors: Record<string, boolean> = {}
        const newPack = _.clone(pack)
        if (targetEnvName && packsInTargetEnv) {
            const childPacksInTargetEnv = pack.childPacks.map((childPackId) =>
                packsInTargetEnv.find((pack) => pack.packId === childPackId)
            )

            if (childPacksInTargetEnv.includes(undefined)) {
                throw new Error(
                    `${pack.packId} contains invalid reference to childPackId`
                )
            }

            newPack.activeFromDate = activeFrom ?? pack.activeFromDate
            newPack.activeToDate = activeTo ?? pack.activeToDate
            newPack.medias = isMediasIncluded ? pack.medias : []
            const validationResults = validate(
                {
                    ...newPack,
                    medias: newPack.medias.map((m) => m.id),
                },
                childPacksInTargetEnv as Pack[]
            )
            const errorDetails = validationResults.errors.error?.details || []
            for (const detail of errorDetails) {
                validationErrors[detail.path[0]] = true
            }
        }

        const isPackInTargetEnvDifferent =
            packInTargetEnv && hasPackChanged(newPack, packInTargetEnv)
        const canCopy =
            targetEnv &&
            (!packInTargetEnv || isPackInTargetEnvDifferent) &&
            _.isEmpty(validationErrors)

        return (
            <section>
                <header className={styles.header}>
                    <h2 className={styles.headerTitle}>
                        Preview & Copy to Environment
                    </h2>
                    <div>
                        Copy to:
                        <Selector
                            value={targetEnvName}
                            onChange={(e) => setTargetEnvName(e.target.value)}
                        >
                            <option disabled value="">
                                Select environment
                            </option>
                            {otherEnvs.map((env) => (
                                <option key={env.name} value={env.name}>
                                    {env.name}
                                </option>
                            ))}
                        </Selector>
                    </div>
                </header>
                <PreviewTable
                    currentEnvName={currentEnv?.name}
                    targetEnvName={targetEnvName}
                    pack={pack}
                    newPack={newPack}
                    packInTargetEnv={packInTargetEnv}
                    isMediasIncluded={isMediasIncluded}
                    onIncludeMediasToggle={setIsMediasIncluded}
                    activeFrom={activeFrom}
                    onActiveFromChange={setActiveFrom}
                    activeTo={activeTo}
                    onActiveToChange={setActiveTo}
                    validationErrors={validationErrors}
                />
                <Footer>
                    <p className={styles.paragraph}>
                        Please note, this process can't be reversed. Please
                        confirm the pack data before copying.
                    </p>
                    <div>
                        <Button kind={ButtonKind.SECONDARY} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            kind={ButtonKind.PRIMARY}
                            disabled={!canCopy}
                            onClick={() =>
                                canCopy &&
                                copyPack(
                                    newPack,
                                    targetEnv,
                                    Boolean(packInTargetEnv)
                                )
                            }
                        >
                            Copy pack
                        </Button>
                    </div>
                </Footer>
            </section>
        )
    }

    return null
}

export default CopyPack

import React from 'react'

const Confirm = (props) => {
    return (
        <div>
            <h1>{props.heading || 'Really do this?'}</h1>
            {props.text && <p>{props.text}</p>}
            <div style={{ textAlign: 'right', marginTop: 30 }}>
                <button className="secondary" onClick={() => props.cancel()}>
                    {props.cancelLabel || 'Cancel'}
                </button>
                <button
                    className="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() => props.done()}
                >
                    {props.okLabel || 'Yes'}
                </button>
            </div>
        </div>
    )
}

export default Confirm

import React, { ChangeEventHandler, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import { packTypeToDisplayText } from '../settings'

import { PackType } from '../types/enums/packtype.enum'

import styles from './PacksHeader.module.scss'

const filterByStatusesOptions = ['all', 'active', 'inactive']
const filterByTypeOptions = [
    'all',
    PackType.PLAYSET,
    PackType.MULTIPACK,
    PackType.CHARACTER_CREATOR,
    PackType.HOME_DESIGNER,
]

type FilterByType = 'status' | 'type'

const filterOptions: Record<FilterByType, string[]> = {
    status: filterByStatusesOptions,
    type: filterByTypeOptions,
}
const filterOptionsDisplayNames: Record<
    FilterByType,
    // fixme We could define enum of possible filter options
    Record<string, string>
> = {
    status: {
        all: 'All',
        active: 'Active',
        inactive: 'Inactive',
    },
    type: {
        all: 'All',
        ...packTypeToDisplayText,
    },
}
const filterDisplayNames: Record<FilterByType, string> = {
    status: 'Status',
    type: 'Type',
}

type PacksHeaderProps = {
    packsCount: number
    searchString: string
    onSearchStringChange: ChangeEventHandler<HTMLInputElement>
    filterValues: { status: string; type: string }
    onFilterChange: ChangeEventHandler<HTMLInputElement>
}

function PacksHeader(props: PacksHeaderProps) {
    const {
        packsCount,
        searchString,
        onSearchStringChange,
        filterValues,
        onFilterChange,
    } = props

    const navigate = useNavigate()

    return (
        <>
            <div className={styles.header}>
                <div>
                    <h1>Packs ({packsCount})</h1>
                    <button
                        className="new"
                        onClick={() => navigate('/newpack/')}
                    >
                        Add new
                    </button>
                </div>
                <input
                    aria-label="search packs"
                    autoFocus
                    className="editInput"
                    type="text"
                    value={searchString}
                    placeholder="Search..."
                    onChange={onSearchStringChange}
                />
            </div>
            <div className={styles.filters}>
                {(
                    Object.entries(filterOptions) as [FilterByType, string[]][]
                ).map(([filterBy, options]) => (
                    <fieldset key={filterBy}>
                        <legend>{filterDisplayNames[filterBy]}</legend>
                        {options.map((option) => (
                            <Fragment key={`${filterBy}-${option}`}>
                                <input
                                    defaultChecked={
                                        filterValues[filterBy] === option
                                    }
                                    id={`${filterBy}-${option}`}
                                    name={filterBy}
                                    type="radio"
                                    value={option}
                                    onChange={onFilterChange}
                                />
                                <label htmlFor={`${filterBy}-${option}`}>
                                    {
                                        filterOptionsDisplayNames[filterBy][
                                            option
                                        ]
                                    }
                                </label>
                            </Fragment>
                        ))}
                    </fieldset>
                ))}
            </div>
        </>
    )
}

export default PacksHeader

import React from 'react'

import { Button, ButtonKind, Footer } from '../shared/ModalV2'

import { getFileNameFromUrl } from './utils'

import { mediaTypeToDisplayText } from '../settings'

import { MediaType as MediaTypeEnum } from '../types/enums/mediatype.enum'

import { Media as MediaType } from '../types/mediaTypes'

import styles from './Media.module.scss'

import Bin from '../assets/bin.svg'
import Link from '../assets/link.svg'

type MediaProps = {
    media: MediaType
    onClose: () => void
}

function Media(props: MediaProps) {
    const { media, onClose } = props

    return (
        <div className={styles.media}>
            <div className={styles.previewWrapper}>
                {media.type === MediaTypeEnum.VIDEO ? (
                    <video controls autoPlay className={styles.preview}>
                        <source src={media.url} />
                    </video>
                ) : (
                    <img
                        alt={`Image with id ${media.id}`}
                        className={styles.preview}
                        src={media.url.replace('{0}', '@3x')}
                    />
                )}
            </div>
            <div className={styles.metadataWrapper}>
                <div>
                    <header className={styles.header}>Media details</header>
                    <dl>
                        <dt className={styles.propertyKey}>Name</dt>
                        <dd className={styles.propertyValue}>
                            {getFileNameFromUrl(media.url)}
                        </dd>
                        <dt className={styles.propertyKey}>Category</dt>
                        <dd className={styles.propertyValue}>
                            {mediaTypeToDisplayText[media.type]}
                        </dd>
                        <dt className={styles.propertyKey}>Uploaded</dt>
                        <dd className={styles.propertyValue}>
                            {media.created}
                        </dd>
                    </dl>
                    <div className={styles.usages}>
                        {/* TODO: Fetch media usage */}
                        <div className={styles.usagesHeader}>
                            <img alt="Link icon" src={Link} width="15px" />
                            <div>Media connections</div>
                        </div>
                        <dl className={styles.usagesByEntity}>
                            <dt>TODO: entity here</dt>
                            <dd className={styles.propertyValue}>
                                <a href="#">TODO: link here</a>
                            </dd>
                        </dl>
                    </div>
                    <p className={styles.deleteInfoParagraph}>
                        Any media connection will be removed if the file is
                        deleted from the CMS.
                    </p>
                    {/* TODO: Implement media deletion */}
                    <Button
                        disabled
                        kind={ButtonKind.DANGER}
                        className={styles.deleteButton}
                    >
                        <img alt="Delete" src={Bin} />
                        Delete media
                    </Button>
                </div>
                <Footer>
                    <Button kind={ButtonKind.SECONDARY} onClick={onClose}>
                        Close
                    </Button>
                </Footer>
            </div>
        </div>
    )
}

export default Media

import React from 'react'

function MapNavigator(props) {
    const { onNext, onPrevious, items, i, name } = props
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginTop: 10,
                marginBottom: 30,
            }}
        >
            <button
                className="warning medium"
                style={{ margin: '0px 15px 0 15px' }}
                onClick={onPrevious}
            >
                {'<'}
            </button>
            <span style={{ fontSize: '1.5em' }}>
                {name}{' '}
                <span style={{ color: 'gray' }}>
                    ({i + 1 + '/' + items.length})
                </span>
            </span>
            <button
                className="warning medium"
                style={{ margin: '0px 15px 0 15px' }}
                onClick={onNext}
            >
                {'>'}
            </button>
        </div>
    )
}

export default MapNavigator

import React from 'react'

import { getBackendBasePath } from './utils/getBackendBasePath'

import logoImg from './assets/logo.svg'

function Login() {
    const loginWithGoogle = () => {
        window.location = `${getBackendBasePath()}/auth/google`
    }

    return (
        <div
            style={{
                textAlign: 'center',
                background: '#B7E0EB',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                tabIndex: '0',
            }}
        >
            <div style={{ margin: 'auto', paddingBottom: 120 }}>
                <img
                    alt="tocaboca_logo"
                    style={{
                        width: 130,
                        margin: 'auto',
                        marginBottom: 20,
                        display: 'block',
                    }}
                    src={logoImg}
                />

                <div style={{ marginBottom: 10 }}>Shop CMS</div>
                <div
                    style={{
                        background: 'white',
                        display: 'inline-block',
                        padding: 30,
                        borderRadius: 20,
                    }}
                >
                    <div>
                        <button onClick={loginWithGoogle}>
                            Login with Google
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login

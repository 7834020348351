import { useState } from 'react'
import axios, { AxiosResponse, isAxiosError } from 'axios'
import _ from 'lodash'

import { handleError } from '../utils/utils.ts'

import { Carousel } from '../types/carouselTypes'
import {
    Pack,
    PackUpdate,
    PartialPackTranslationType,
} from '../types/packTypes'
import { Tab } from '../types/tabTypes'

type UsePackOptions = {
    basepath?: string
}

export function usePack(packId: string, options: UsePackOptions = {}) {
    const { basepath } = options
    const [pack, setPack] = useState<Pack | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()
    const [errorStatus, setErrorStatus] = useState<number | undefined>()

    async function fetchPack() {
        setPack(undefined)
        setIsLoading(true)
        setError(undefined)
        setErrorStatus(undefined)

        try {
            const url = basepath
                ? `${basepath}/v2/pack/${packId}`
                : `/api/v2/pack/${packId}`
            const {
                data: { pack },
            } = await axios.get(url)
            setPack(pack)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            }
            if (isAxiosError(err)) {
                setErrorStatus(err.response?.status)
                setError(err.response?.data.message ?? err.message)
            }
        }

        setIsLoading(false)
    }

    return {
        pack,
        fetch: fetchPack,
        isLoading,
        error,
        errorStatus,
    }
}

type UsePacksOptions = {
    basepath?: string
}

export function usePacks(options: UsePacksOptions = {}) {
    const { basepath } = options
    const [packs, setPacks] = useState<Pack[] | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    async function fetchPacks() {
        setPacks(undefined)
        setIsLoading(true)
        setError(undefined)

        try {
            const url = basepath ? `${basepath}/packs` : '/api/packs'
            const {
                data: { packs },
            } = await axios.get(url)
            setPacks(packs)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            }
            if (isAxiosError(err)) {
                setError(err.response?.data.message ?? err.message)
            }
        }

        setIsLoading(false)
    }

    return {
        packs,
        fetch: fetchPacks,
        isLoading,
        error,
    }
}

type UseCreatePackOptions = {
    basepath?: string
}

export function useCreatePack(options: UseCreatePackOptions = {}) {
    const { basepath } = options
    const [createdPack, setCreatedPack] = useState<Pack | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    async function createPack(pack: PackUpdate) {
        setCreatedPack(undefined)
        setIsLoading(true)

        try {
            const url = basepath ? `${basepath}/pack/` : '/api/pack/'
            const { data } = await axios.post(url, { pack })
            setCreatedPack(data)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            }
            if (isAxiosError(err)) {
                setError(err.response?.data.message ?? err.message)
            }
        }

        setIsLoading(false)
    }

    return {
        createPack,
        pack: createdPack,
        isLoading,
        error,
    }
}

type UseUpdatePackOptions = {
    basepath?: string
}

export function useUpdatePack(options: UseUpdatePackOptions = {}) {
    const { basepath } = options
    const [updatedPack, setUpdatedPack] = useState<Pack | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    async function updatePack(pack: PackUpdate) {
        setUpdatedPack(undefined)
        setIsLoading(true)

        try {
            const url = basepath
                ? `${basepath}/pack/${pack.packId}`
                : `/api/pack/${pack.packId}`
            const { data } = await axios.put(url, { pack })
            setUpdatedPack(data)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            }
            if (isAxiosError(err)) {
                setError(err.response?.data.message ?? err.message)
            }
        }

        setIsLoading(false)
    }

    return {
        updatePack,
        pack: updatedPack,
        isLoading,
        error,
    }
}

export function usePackMembership(packId: string) {
    const [membership, setMembership] = useState<{
        packs: Pack[]
        carousels: Carousel[]
        tabs: Tab[]
    }>({
        packs: [],
        carousels: [],
        tabs: [],
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    async function fetchPackMembership() {
        setIsLoading(true)
        setMembership({ packs: [], carousels: [], tabs: [] })
        setError(undefined)

        try {
            const { data } = await axios.get(`/api/v2/pack/${packId}/usages`)

            setMembership(data)
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message)
            }
            if (isAxiosError(error)) {
                setError(error.response?.data.message ?? error.message)
            }
        }

        setIsLoading(false)
    }

    return {
        membership,
        fetch: fetchPackMembership,
        isLoading,
        error,
    }
}

export type PackDescriptionType = {
    displayName: PartialPackTranslationType
    features: PartialPackTranslationType
}

export function useDescriptionFromGoogleSheet(packName: string) {
    const [description, setDescription] = useState<PackDescriptionType | null>(
        null
    )
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    const loadGoogleSheetData = async () => {
        try {
            setError(undefined)
            setIsLoading(true)

            const {
                data,
            }: AxiosResponse<{
                name: PartialPackTranslationType
                wyg: PartialPackTranslationType
            }> = await axios.get(`/api/v2/getpackfromsource/${packName}`)

            // Everything went well, but there are no translations from Google sheet for this String ID
            if (_.isEmpty(data.name)) {
                throw new Error('There are no translations for this String ID!')
            }

            setDescription({
                displayName: data.name,
                features: data.wyg || {},
            })
        } catch (error) {
            const errorMessage = handleError(
                'Could not load pack description from the spreadsheet',
                error,
                false
            )

            setDescription(null)
            setError(errorMessage)
        }

        setIsLoading(false)
    }

    return {
        description,
        load: loadGoogleSheetData,
        isLoading: isLoading,
        error,
    }
}

type ExportResult = {
    name: string
    url?: string
    list?: boolean
}

export enum IapExportStatus {
    Exporting,
    Exported,
    PartiallyExported,
    Failed,
    NotSet,
}

export function useIapExport() {
    const [status, setStatus] = useState<IapExportStatus>(
        IapExportStatus.NotSet
    )
    const [error, setError] = useState<string | undefined>()
    const [results, setResults] = useState<ExportResult[]>([])

    const exportIap = async (packId: Pack['packId']) => {
        try {
            setError(undefined)
            setStatus(IapExportStatus.Exporting)

            const response = await axios.post<{
                success: boolean
                data: Array<Record<string, string> | string>
            }>(`/api/v2/exportpacktostorefront/${packId}`)
            if (response.data.success) {
                setResults(
                    response.data.data.map((product) => {
                        const { productId: name, url } = product as Record<
                            string,
                            string
                        >

                        return {
                            name,
                            url,
                            list: true,
                        }
                    })
                )
                setStatus(IapExportStatus.Exported)
            } else {
                const exportSuccess = response.data.data
                    .filter((product) => _.isObject(product) && product.url)
                    .map((product) => {
                        const { productId: name, url } = product as Record<
                            string,
                            string
                        >

                        return {
                            name,
                            url,
                            list: true,
                        }
                    })
                const exportFailed = response.data.data
                    .filter((product) => !_.isObject(product) || !product.url)
                    .map((product) => ({ name: product as string, list: true }))

                setResults(
                    [{ name: 'The following SKUs were exported:' }].concat(
                        exportSuccess,
                        [{ name: 'The following SKUs failed to export:' }],
                        exportFailed
                    )
                )
                setStatus(IapExportStatus.PartiallyExported)
            }
        } catch (e) {
            const message = handleError(
                'Failed to send data to the Apple store',
                e,
                false
            )
            setError(message)
            setStatus(IapExportStatus.Failed)
        }
        setTimeout(() => {
            setStatus(IapExportStatus.NotSet)
        }, 5000)
    }

    return {
        status,
        exportIap,
        error,
        results,
    }
}

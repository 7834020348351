import React, { ReactNode } from 'react'

import styles from './Status.module.scss'

import errorImg from '../../assets/error.png'
import validImg from '../../assets/valid.png'

export enum StatusType {
    Info = 'INFO',
    Error = 'ERROR',
}
export type StatusTypeUnion = `${StatusType}`

export type StatusProps = {
    type: StatusTypeUnion
    header: string
    body: ReactNode
}

const iconsByType = {
    [StatusType.Info]: { src: validImg, alt: 'Information' },
    [StatusType.Error]: { src: errorImg, alt: 'Error' },
}

export function Status(props: StatusProps) {
    const { type, header, body } = props
    const icon = iconsByType[type]

    return (
        <div className={styles.wrapper}>
            <img className={styles.image} {...icon} />
            <header className={styles.header}>{header}</header>
            <div className={styles.body}>{body}</div>
        </div>
    )
}

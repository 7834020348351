import React from 'react'

import { SpeechBubble } from '../../shared'

export const DragNDropSpeechBubble = () => {
    return (
        <SpeechBubble
            style={{
                justifyContent: 'left',
                marginTop: 10,
                marginBottom: 20,
            }}
        >
            Drag and drop to rearrange :)
            <br />
            Wanna upload new files? just drag them and drop them below :)
        </SpeechBubble>
    )
}

import React from 'react'
import { diff } from 'deep-diff'

const Compare = (props) => {
    const { object1, object2, compareType } = props

    const diffs = diff(object1, object2)
    let mediasLhs = [],
        mediasRhs = []
    if (compareType === 'pack') {
        mediasLhs = object1.medias
        mediasRhs = object2.medias
    }

    return (
        <div>
            <h1>{props.heading || 'Really do this?'}</h1>
            {props.text && <p>{props.text}</p>}
            {
                <div className="wrapper-diff">
                    {compareType === 'pack' ? (
                        <div
                            key={`diff-medias-current`}
                            className="wrapper-diff-row"
                        >
                            <div className="diff-title">Changed medias</div>
                            {mediasLhs.map((m, i) => (
                                <div
                                    key={`diff-media-lhs-${i}`}
                                    className="container-diff container-diff-lhs"
                                >
                                    <span>{m.type}</span>
                                    <img
                                        style={{ width: '100%' }}
                                        src={m.url.replace('{0}', '@1x')}
                                        alt={'id:' + m.id}
                                    />
                                </div>
                            ))}
                            {mediasRhs.map((m, i) => (
                                <div
                                    key={`diff-media-rhs-${i}`}
                                    className="container-diff container-diff-rhs"
                                >
                                    <span>{m.type}</span>
                                    <img
                                        style={{ width: '100%' }}
                                        src={m.url.replace('{0}', '@1x')}
                                        alt={'id:' + m.id}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : null}
                    {diffs ? (
                        diffs.map((diff, i) => {
                            // Show medias differently
                            if (diff.path[0] === 'medias') {
                                return
                            }
                            switch (diff.kind) {
                                case 'E':
                                    return (
                                        <div
                                            key={`diff-${i}`}
                                            className="wrapper-diff-row"
                                        >
                                            <div className="diff-title">
                                                Changed{' '}
                                                {diff.path.map(
                                                    (pathNode, i) => {
                                                        if (
                                                            typeof pathNode ===
                                                            'number'
                                                        )
                                                            return ''
                                                        if (
                                                            i <
                                                            diff.path.length - 1
                                                        )
                                                            return `${pathNode}, `
                                                        else return pathNode
                                                    }
                                                )}
                                            </div>
                                            <div className="container-diff container-diff-lhs">
                                                {typeof diff.lhs === 'boolean'
                                                    ? diff.lhs.toString()
                                                    : diff.lhs}
                                            </div>
                                            <div className="container-diff container-diff-rhs">
                                                {typeof diff.rhs === 'boolean'
                                                    ? diff.rhs.toString()
                                                    : diff.rhs}
                                            </div>
                                        </div>
                                    )
                                case 'A':
                                    return (
                                        <div
                                            key={`diff-${i}`}
                                            className="wrapper-diff-row"
                                        >
                                            <div className="diff-title">
                                                Changed{' '}
                                                {diff.path.map(
                                                    (pathNode, i) => {
                                                        if (
                                                            typeof pathNode ===
                                                            'number'
                                                        )
                                                            return ''
                                                        if (
                                                            i <
                                                            diff.path.length - 1
                                                        )
                                                            return `${pathNode}, `
                                                        else return pathNode
                                                    }
                                                )}{' '}
                                                (index {diff.index})
                                            </div>
                                            <div className="container-diff">
                                                {diff.item.kind === 'D'
                                                    ? 'Deleted item'
                                                    : diff.item.kind === 'N'
                                                    ? 'Added item'
                                                    : 'Changed item'}
                                            </div>
                                        </div>
                                    )
                                case 'N':
                                    return (
                                        <div
                                            key={`diff-${i}`}
                                            className="wrapper-diff-row"
                                        >
                                            <div className="diff-title">
                                                Added{' '}
                                                {diff.path.map(
                                                    (pathNode, i) => {
                                                        if (
                                                            typeof pathNode ===
                                                            'number'
                                                        )
                                                            return ''
                                                        if (
                                                            i <
                                                            diff.path.length - 1
                                                        )
                                                            return `${pathNode}, `
                                                        else return pathNode
                                                    }
                                                )}
                                            </div>
                                            <div className="container-diff">
                                                {diff.rhs}
                                            </div>
                                        </div>
                                    )
                                case 'D':
                                    return (
                                        <div
                                            key={`diff-${i}`}
                                            className="wrapper-diff-row"
                                        >
                                            <div className="diff-title">
                                                Deleted{' '}
                                                {diff.path.map(
                                                    (pathNode, i) => {
                                                        if (
                                                            typeof pathNode ===
                                                            'number'
                                                        )
                                                            return ''
                                                        if (
                                                            i <
                                                            diff.path.length - 1
                                                        )
                                                            return `${pathNode}, `
                                                        else return pathNode
                                                    }
                                                )}
                                            </div>
                                            <div className="container-diff">
                                                {diff.lhs}
                                            </div>
                                        </div>
                                    )
                            }
                        })
                    ) : (
                        <div className="wrapper-diff-row">
                            <div className="container-diff">No changes :)</div>
                        </div>
                    )}
                </div>
            }
            <div style={{ textAlign: 'right', marginTop: 30 }}>
                <button className="secondary" onClick={() => props.cancel()}>
                    {props.cancelLabel || 'Cancel'}
                </button>
                <button
                    className="primary"
                    style={{ marginLeft: 10 }}
                    onClick={() => props.done()}
                >
                    {props.okLabel || 'Yes'}
                </button>
            </div>
        </div>
    )
}

export default Compare

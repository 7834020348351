import React from 'react'

import { Modal } from '../shared'

const ImportButton = ({ title, save, setConfig }) => {
    const importABConfig = async () => {
        const config = await Modal.prompt({
            heading: 'Import configuration',
            text: 'Paste JSON data below',
        })
        try {
            const parsed = JSON.parse(config)
            if (typeof parsed === 'boolean') return // modal returns true if empty
            save(config)
            setConfig(parsed)
        } catch (e) {
            Modal.alert({
                heading: 'Sorry',
                text: "That wasn't valid JSON. Please try again.",
            })
        }
    }

    return (
        <button className="new inSectionHeading" onClick={importABConfig}>
            {title || 'Import'}
        </button>
    )
}

export default ImportButton

import React, { Component } from 'react'
import { ListManager } from 'react-beautiful-dnd-grid'

import { MediaType } from '../types/enums/mediatype.enum'

import savingImg from '../assets/saving.png'

class DraggablePhotos extends Component {
    renderDraggableItem = (item) => {
        const { onDelete, type, object } = this.props
        const imageSize = this.props.imageSize || '@1x'
        item = object ? item[object] : item
        return (
            <div
                style={{ userSelect: 'none' }}
                className={type === MediaType.VIDEO ? 'video' : 'photo'}
            >
                <div className="deletable">
                    {type !== MediaType.VIDEO && (
                        <img
                            src={item.url.replace('{0}', imageSize)}
                            alt={'id:' + item.id}
                        />
                    )}
                    {type === MediaType.VIDEO && (
                        <video
                            style={{
                                maxWidth: 450,
                            }}
                            data-testid={'video:' + item.id}
                            controls
                        >
                            <source src={item.url} type="video/mp4" />
                        </video>
                    )}
                    {item.uploading && (
                        <div className="uploading">
                            Uploading{' '}
                            <img
                                alt="loading"
                                className="loading"
                                style={{
                                    marginLeft: 10,
                                    marginBottom: -6,
                                    width: 22,
                                    opacity: 0.8,
                                }}
                                src={savingImg}
                            />
                        </div>
                    )}

                    {onDelete && (
                        <button
                            className="deleteIcon"
                            onClick={() => onDelete(item.idx, type)}
                        />
                    )}
                </div>
            </div>
        )
    }

    render() {
        const { items, object, onDragEnd, type } = this.props
        const draggableItems = items.map((item, idx) => ({
            idx,
            id: item.id,
            uploading: item.uploading,
            url: item.url,
            [object]: item[object],
        }))

        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '0.4%',
                }}
            >
                <ListManager
                    items={draggableItems}
                    direction="horizontal"
                    maxItems={1}
                    render={this.renderDraggableItem}
                    onDragEnd={(sourceIndex, destinationIndex) =>
                        onDragEnd({
                            source: {
                                index: sourceIndex,
                                droppableId: type,
                            },
                            destination: { index: destinationIndex },
                        })
                    }
                />
            </div>
        )
    }
}

export default DraggablePhotos

import React, { ReactNode } from 'react'

import StatusLabel, { ChangeStatuses, ChangeStatusesUnion } from './StatusLabel'

import styles from './Table.module.scss'

type TableProps = {
    summary: ReactNode
    headerRow: ReactNode[]
    dataRows: ReactNode[][]
    isExpanded: boolean
    isUpdated?: boolean
    isInvalid?: boolean
}

function Table(props: TableProps) {
    const { summary, headerRow, dataRows, isExpanded, isUpdated, isInvalid } =
        props

    let changeStatus: ChangeStatusesUnion | undefined
    if (isUpdated) {
        changeStatus = ChangeStatuses.UPDATED
    }
    if (isInvalid) {
        changeStatus = ChangeStatuses.INVALID
    }

    return (
        <>
            <div className={styles.summary}>
                {summary}
                {changeStatus && <StatusLabel status={changeStatus} />}
            </div>
            {isExpanded && (
                <div className={styles.tableWrapper}>
                    <table className={styles.table}>
                        <thead className={styles.headerRow}>
                            <tr className={styles.row}>
                                {headerRow.map((headerCell, i) => (
                                    <th
                                        key={i}
                                        className={styles.header}
                                        scope="col"
                                    >
                                        {headerCell}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {dataRows.map((dataRow, i) => (
                                <tr key={i} className={styles.row}>
                                    {dataRow.map((dataCell, i) => (
                                        <td key={i} className={styles.data}>
                                            {dataCell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}

export default Table

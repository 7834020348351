/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { ListManager } from 'react-beautiful-dnd-grid'

import type { ImageIconTypeUnion } from '../types/enums/imageicontype.enum'
import { MediaType } from '../types/enums/mediatype.enum.ts'

import { Media } from '../types/mediaTypes'

export type DraggableItemType = {
    media: Media | any
    overlayText?: string
    buttonType: ImageIconTypeUnion
    onClick: (mediaId: number) => void
}
type DraggableProps = {
    items: DraggableItemType[] | any
    renderer: (item: DraggableItemType) => React.ReactElement
    onDragEnd: (result: {
        source: { index: number; droppableId: MediaType }
        destination: { index: number }
    }) => void
    object?: string
}

function Draggable(props: DraggableProps) {
    const { items, object, renderer, onDragEnd } = props
    const draggableItems = items.map((item: DraggableItemType) => {
        // CRUTCH for cases when media is a new file that was just uploaded
        if (object) {
            return {
                ...item,
                [object]: item[object as keyof typeof item],
            }
        } else {
            return item
        }
    })
    const itemType = MediaType.SCREENSHOT
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '0.4%',
                }}
            >
                <ListManager
                    items={draggableItems}
                    direction="horizontal"
                    maxItems={1}
                    render={(item) => {
                        // CRUTCH for cases when media is a new file that was just uploaded
                        item = object ? item[object] : item
                        return renderer(item)
                    }}
                    onDragEnd={(sourceIndex, destinationIndex) =>
                        onDragEnd({
                            source: {
                                index: sourceIndex,
                                droppableId: itemType,
                            },
                            destination: { index: destinationIndex },
                        })
                    }
                />
            </div>
        </>
    )
}

export default Draggable

import React, { ChangeEvent, useEffect, useState } from 'react'

import { LabelInput, SpeechBubble } from '../../shared'

import {
    PackDescriptionType,
    useDescriptionFromGoogleSheet,
} from '../../hooks/packs.ts'

import { languages } from '../../utils/utils.ts'

import {
    maxDescriptionCharacterCount,
    maxDisplayNameCharacterCount,
} from '../../settings'

import { LanguageType } from '../../types/enums/languagetype.enum.ts'

import { Pack } from '../../types/packTypes'

import styles from './PackDescription.module.scss'

export enum PossibleDescriptionFields {
    DisplayName = 'displayName',
    Features = 'features',
}

type PackDescriptionProps = {
    pack: Pack
    language: LanguageType
    onInputChange: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        child?: string | null,
        index?: number | null
    ) => void
    onGoogleSheetDataLoaded: (description: PackDescriptionType) => void
}

export function PackDescription(props: PackDescriptionProps) {
    const { pack, language, onInputChange, onGoogleSheetDataLoaded } = props

    const [isMessageShown, setIsMessageShown] = useState<boolean>(false)
    const {
        description,
        load: loadGoogleSheetData,
        isLoading,
        error: errorMessage,
    } = useDescriptionFromGoogleSheet(pack.name)

    function getLoadingStatusMessage(): string {
        if (errorMessage) {
            return `That didn't work. Sorry! Server says: ${errorMessage}`
        }

        if (isLoading) {
            return 'Loading from google...'
        }

        if (description) {
            return "I've updated the text for you!"
        }

        return ''
    }

    useEffect(() => {
        if (description) {
            onGoogleSheetDataLoaded(description)
        }
    }, [description])

    useEffect(() => {
        if (isLoading) {
            setIsMessageShown(true)
        } else {
            setTimeout(() => {
                setIsMessageShown(false)
            }, 5000)
        }
    }, [isLoading])

    return (
        <>
            <LabelInput
                object={pack}
                size="wide"
                name={PossibleDescriptionFields.DisplayName}
                tooltip={`Localized name shown in the shop. Length should be max ${maxDisplayNameCharacterCount} characters.`}
                language={language}
                label={'display name in '}
                labelHighlight={languages[language]}
                value={pack.displayName[language] || ''}
                onChange={onInputChange}
            />

            <br />

            <textarea
                onChange={onInputChange}
                name={PossibleDescriptionFields.Features}
                data-testid="features"
                className={styles.featuresTextarea}
                value={pack.features[language] || ''}
                maxLength={maxDescriptionCharacterCount}
                placeholder={`Add pack's description here.\n\n(i) Recommended length is ${maxDescriptionCharacterCount} characters`}
            />
            <textarea
                name="description"
                data-testid="description"
                disabled={true}
                readOnly={true}
                className={styles.descriptionTextarea}
                value={`Example: A short description that describes this pack. Include a bullet list like this:\n\n- Much funsies\n- Very stuff\n- Plenty worth\n\n\n(i) Recommended length is ${maxDescriptionCharacterCount} characters`}
            />

            <div className={styles.newRow} />

            {isMessageShown ? (
                <SpeechBubble
                    style={{
                        justifyContent: 'left',
                        marginBottom: 10,
                    }}
                >
                    {getLoadingStatusMessage()}
                </SpeechBubble>
            ) : (
                <button onClick={loadGoogleSheetData}>
                    Load from Google Sheet
                </button>
            )}
        </>
    )
}

import React, { ChangeEventHandler } from 'react'

import { Tab } from '../../../../types/tabTypes'

import styles from './ShopTabColor.module.scss'

type ShopTabColorProps = {
    tab: Tab
    onColorInputChange: ChangeEventHandler
}

export const ShopTabColor = (props: ShopTabColorProps) => {
    const { tab, onColorInputChange } = props
    return (
        <div>
            <h2>Selected Tab Color</h2>
            <div className="labelInput">
                <label htmlFor="buttonColor">Hex color code with #</label>
                <div
                    data-testid="colorPreview"
                    className={styles.colorPreview}
                    style={{
                        backgroundColor: tab.buttonColor ?? '',
                    }}
                >
                    &nbsp;
                </div>
                <input
                    id="buttonColor"
                    name="buttonColor"
                    type="text"
                    className="tiny"
                    maxLength={7}
                    placeholder="#357a0c"
                    value={tab.buttonColor ?? ''}
                    onChange={onColorInputChange}
                />
            </div>
        </div>
    )
}

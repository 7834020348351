import React, { useEffect, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'

import ImportButton from './ImportButton'
import Output from './Output'
import { ManagePlaysets, SpeechBubble } from '../shared'

import { handleError } from '../utils/utils'

let shopConfiguration
try {
    shopConfiguration = JSON.parse(localStorage.getItem('ShopConfiguration'))
    if (!shopConfiguration.hiddenPacks) shopConfiguration.hiddenPacks = []
    if (!shopConfiguration.newPacks) shopConfiguration.newPacks = []
} catch (i) {
    shopConfiguration = { hiddenPacks: [], newPacks: [] }
    console.log('No local configuration')
}

const ShopConfiguration = () => {
    const [shopConfig, setShopConfig] = useState(shopConfiguration)
    const [hasLoaded, setHasLoaded] = useState(false)
    const [packs, setPacks] = useState([])

    const fetchData = async () => {
        setHasLoaded(true)
        let response
        try {
            response = await axios.get('/api/packs')
        } catch (err) {
            return handleError('Failed to fetch packs', err)
        }
        let packs = response.data.packs
        console.log(`Got ${packs.length} packs`)
        setPacks(packs)
    }

    useEffect(() => {
        save()
        if (!hasLoaded) fetchData()
    }, [shopConfig])

    function save(config) {
        console.log('Save ShopConfiguration')
        const s = JSON.stringify(config || shopConfig)
        localStorage.setItem('ShopConfiguration', s)
    }

    function toggleHidden({ packId }) {
        toggle(packId, 'hiddenPacks')
    }

    function toggleNew({ packId }) {
        toggle(packId, 'newPacks')
    }

    function toggle(packId, collection) {
        let config = _.cloneDeep(shopConfig)
        console.log(config)
        let hidden = config[collection]
        let ix = hidden.indexOf(packId)
        ix > -1 ? hidden.splice(ix, 1) : hidden.push(packId)
        console.log(config)
        setShopConfig(config)
    }

    return (
        <>
            <ImportButton save={save} setConfig={setShopConfig} />
            <SpeechBubble
                width="500"
                style={{
                    marginTop: 10,
                    marginBottom: 30,
                    marginLeft: 0,
                    marginRight: 0,
                }}
            >
                Please note that the Remote Configuration in Firebase may not be
                available in all locations (eg China).
            </SpeechBubble>
            <ManagePlaysets
                title="Select hidden packs"
                items={shopConfig.hiddenPacks}
                playsets={packs}
                togglePlayset={toggleHidden}
            />
            <ManagePlaysets
                title="Select new packs"
                items={shopConfig.newPacks}
                playsets={packs}
                togglePlayset={toggleNew}
            />

            <Output data={shopConfig} height={50} />
        </>
    )
}

export default ShopConfiguration

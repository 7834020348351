import axios from 'axios'
import { memoize } from 'lodash'

import { handleError } from './utils'

export const Features = {
    in_app_events: 'in_app_events',
}

const getEnabledFeatures = memoize(async (basepath = ''): Promise<string[]> => {
    try {
        const response = await axios.get(`${basepath}/features`)

        return response.data
    } catch (e) {
        handleError("Couldn't fetch enabled features", e)

        return []
    }
})

/**
 * Checks if a feature is enabled.
 *
 * @async
 * @param {keyof typeof Features} featureName - The name of the feature to check.
 * @param {string} [basepath] - The base path for the environment to fetch enabled features from.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if the feature is enabled.
 */
export const isFeatureEnabled = async function (
    featureName: keyof typeof Features,
    basepath?: string
): Promise<boolean> {
    const enabledFeatures = await getEnabledFeatures(basepath)

    return enabledFeatures.includes(featureName)
}

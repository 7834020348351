import React from 'react'

import { getPackDisplayName } from '../utils/utils'

function PackLink(props) {
    let data = props.data
    let name = getPackDisplayName(data)
    return (
        <a
            rel="noopener noreferrer"
            style={{ color: '#05BC6E' }}
            target="_blank"
            href={'/pack/' + data.packId}
        >
            {name}
        </a>
    )
}

export default PackLink

import React from 'react'
import classNames from 'classnames'

import { ProductUpdateStatus } from '../types/enums/priceUpdateStatus.enum.ts'

import { ProductPrices } from '../types/priceAutomation'

import styles from './sku-update.module.scss'

const UpdateStatusToText = {
    [ProductUpdateStatus.NotStarted]: 'Not started',
    [ProductUpdateStatus.Updating]: 'Updating',
    [ProductUpdateStatus.Scheduled]: 'Scheduled',
    [ProductUpdateStatus.Updated]: 'Updated',
    [ProductUpdateStatus.Error]: 'Error',
    [ProductUpdateStatus.InvalidPrice]: 'Invalid price',
}

const UpdateStatusToClass = {
    [ProductUpdateStatus.NotStarted]: styles.notStarted,
    [ProductUpdateStatus.Updating]: styles.updating,
    [ProductUpdateStatus.Scheduled]: styles.scheduled,
    [ProductUpdateStatus.Updated]: styles.updated,
    [ProductUpdateStatus.Error]: styles.error,
    [ProductUpdateStatus.InvalidPrice]: styles.invalidPrice,
}

type ProductRowProps = {
    product: ProductPrices
    status: ProductUpdateStatus
    error?: string
    isSelected: boolean
    isUpdating: boolean
    onCheckboxClick: (id: string) => void
}

export const ProductRow = (props: ProductRowProps) => {
    const { product, status, isSelected, error, isUpdating } = props

    const onCheckboxClick = () => {
        props.onCheckboxClick(product.productId)
    }

    return (
        <div className={styles.productRow}>
            <input
                id={product.productId}
                type="checkbox"
                className={styles.checkbox}
                checked={isSelected}
                onChange={onCheckboxClick}
                disabled={
                    ProductUpdateStatus.Updated === status ||
                    ProductUpdateStatus.InvalidPrice === status ||
                    isUpdating
                }
            />

            <div
                className={classNames(
                    styles.status,
                    UpdateStatusToClass[status]
                )}
                title={error}
            >
                {UpdateStatusToText[status]}
            </div>

            <div>
                <label htmlFor={product.productId}>{product.productId}</label>
                {status === ProductUpdateStatus.Error && error ? (
                    <p className={styles.errorMessage}>{error}</p>
                ) : null}
            </div>
        </div>
    )
}
